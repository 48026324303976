.about-wrapper {
  width: 100%;
  padding-bottom: 2rem;

  .back-wrapper {
    width: 100%;
    height: calc(100vh - 90.8px);
    position: relative;
    &:after {
      background: rgb(0,0,0);
      background: -moz-linear-gradient(180deg, rgba(0,0,0,0.15) 20%, rgba(0,0,0,1) 20%, rgba(0,0,0,0.35) 20%, rgba(0,0,0,0.35) 40%, rgba(0,0,0,0.6) 40%, rgba(0,0,0,0.65) 60%, rgba(0,0,0,0.85) 60%, rgba(0,0,0,0.85) 80%, rgba(0,0,0,1) 80%, rgba(0,0,0,1) 100%);
      background: -webkit-linear-gradient(180deg, rgba(0,0,0,0.15) 20%, rgba(0,0,0,1) 20%, rgba(0,0,0,0.35) 20%, rgba(0,0,0,0.35) 40%, rgba(0,0,0,0.6) 40%, rgba(0,0,0,0.65) 60%, rgba(0,0,0,0.85) 60%, rgba(0,0,0,0.85) 80%, rgba(0,0,0,1) 80%, rgba(0,0,0,1) 100%);
      background: linear-gradient(180deg, rgba(0,0,0,0.15) 20%, rgba(0,0,0,1) 20%, rgba(0,0,0,0.35) 20%, rgba(0,0,0,0.35) 40%, rgba(0,0,0,0.6) 40%, rgba(0,0,0,0.65) 60%, rgba(0,0,0,0.85) 60%, rgba(0,0,0,0.85) 80%, rgba(0,0,0,1) 80%, rgba(0,0,0,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
      content: "";
      position: absolute;
      left: 0px;
      bottom: 0px;
      width: 100%;
      height: 150px !important;
      z-index: 1;
    }
    .bg-blue-img {
      width: 100%;
      height: 100%;
    }
    

    .about-logo {
      // width: 1200px;
      height: 479px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }

  .about-heading {
    font-family: "ProximaNova-SemiBold";
    font-size: 50px;
    line-height: 53px;
    color: #ffffff;
    background-color: black;
    text-align: center;
    width: 820px;
    margin: auto;
    padding-top: 7px;
    padding-bottom: 54px;
    span {
      color: #ff6ce2;
      font-family: "ProximaNova-SemiBold";
    }
  }

  .about-info-wrapper {
    font-family: "Proxima-Nova-Medium";
    font-size: 20px;
    line-height: 30px;
    padding-left: 140px;
    background-color: black;
    width: 800px;

    p:last-child {
      margin-bottom: 0;
    }
    p {
      font-family: "Proxima-Nova-Medium";
      color: #ffffff;
      font-size: 20px;
      line-height: 30px;
      margin-top: 0;

      span {
        font-family: "Proxima-Nova-Medium";
        font-size: 20px;
        line-height: 30px;
        color: #ff6ce2;
        text-align: left;
      }
    }
  }

  .about-desc-wrapper {
    width: 100%;
    border-top: 1px solid #c242a8;
    padding-top: 54px;
    padding-bottom: 209px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .about-wrapper .about-heading {
    font-family: "ProximaNova-SemiBold";
    font-size: 23px !important;
    background-color: black;
    padding: 11px 0px !important;
    line-height: 1.6;
    width: 95%;
  }

  .about-wrapper .about-info-wrapper p {
    padding-top: 2rem !important;
    width: 80% !important;
    text-align: left;
    font-size: 20px;
    color: #ffffff;
    line-height: 1.6;
    font-family: "ProximaNova-Regular";
    height: auto;
    margin-left: 30px;
  }
  .about-wrapper .about-info-wrapper {
    background-color: black;
    font-size: 20px;
    line-height: 1.6px;
    padding-left: 0px;
    width: 100%;
  }

  .about-wrapper .back-wrapper {
    width: 100%;
    height: 400px;
    position: relative;
  }

  .about-wrapper .back-wrapper .about-logo {
    visibility: hidden;
  }
  .about-wrapper .about-info-wrapper p span {
    font-size: 20px;
    line-height: 1.6;
  }
  .about-desc-wrapper {
    padding-top: 54px;
    padding-bottom: 30px !important;
  }
  // .about-wrapper .about-info-wrapper {
  //   background-color: black;
  //   font-size: 20px;
  //   line-height: 30px;
  //   padding: 0px 30px;
  //   width: 100%;
  //   font-family: "Proxima-Nova-Medium";
  // }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .about-wrapper .about-heading {
    font-size: 33px;
    line-height: 50px;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 30px;
  }
  .about-wrapper .about-desc-wrapper {
    padding-bottom: 50px;
  }
  .about-wrapper .about-info-wrapper {
    padding-left: 90px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1900px) {
  .about-wrapper .about-desc-wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .about-wrapper .about-heading {
    font-size: 30px;
    line-height: 40px;
    width: 600px;
  }
  .about-wrapper .about-info-wrapper {
    font-size: 22px;
    line-height: 30px;
    padding-left: 117px;
    background-color: black;
    width: 600px;
  }
  .about-wrapper .about-info-wrapper p {
    font-size: 16px;
    line-height: 25px;
  }
  .about-wrapper .about-info-wrapper p span {
    font-size: 16px;
    line-height: 25px;
  }
}

.chat-button {
  position: fixed;
  right: 0;
  bottom: 0px;
  background-image: linear-gradient(to top, #360d2a, #5c0e44);
  color: white;
  display: none;
}
