// .nevigation-wrapper {
//     background-color: black;
//     height: 5rem;
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     align-items: center;
//     gap: 2rem;
//     padding: 0 1rem;

//     .Logo-wrapper {
//       width: 100px;
//       height: 40px;
//       margin-left: 1rem;
//       img {
//         width: 100%;
//       }
//     }
//     .menu-wrapper {
//       display: flex;
//       flex-direction: row;
//       justify-content: flex-start;
//       align-items: center;
//       gap: 1rem;
//       margin-right: 1rem;

//       a {
//         text-decoration: none;
//         color: white;
//         font-size: 1rem;
//         cursor: pointer;
//       }
//       .active {
//         font-weight: bold;
//         color: #C242A8;
//       }
//     }
//   }

.page-container{
    padding-top: 90px; //header height
    min-height: 75vh; //min-height
}
