.event-container-wrapper {
  background-color: rgb(0, 0, 0);
  width: 100%;
  height: auto;
  padding-bottom: 100px;

  // .swiper {
  //   overflow-x: scroll;
  //   overflow-y: hidden;
  // }

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  .heroContainer {
    .imgCarousel {
      .info-event-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 117px;

        .event-direction {
          margin-left: -117px !important;
          margin-top: 100px;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;
          width: 225px;
        }
        .line {
          width: 3rem;
          border-bottom: 2px solid #c242a8;
        }
        .new-timer {
          font-size: 18px;
          line-height: 18px;
          font-family: "ProximaNova-Extra-Condensed-Bold";
          color: white;
        }
        .text-live {
          font-size: 18px;
          line-height: 18px;
          font-family: "Proxima-Nova-Medium";
          color: #c242a8;
          text-transform: uppercase;
          .line-doat {
            height: 10px;
            width: 10px;
            background-color: #c242a8;
            border-radius: 50%;
            margin-left: 10px;
            display: inline-block;
          }
        }
        .info-event-main-heading {
          color: #c242a8;
          font-size: 30px;
          letter-spacing: 0.6px;
          text-transform: uppercase;
          margin-top: 1rem;
        }

        .info-event-heading {
          font-size: 100px;
          line-height: 35px;
          letter-spacing: -2px;
          text-transform: uppercase;
          font-family: "ProximaNova-Extra-Condensed-Bold";
          color: #ffffff;
          margin-top: 135px;
          margin-top: 180px;
        }

        .info-event-title {
          color: #c242a8;
          font-size: 30px;
          letter-spacing: 0.6px;
          line-height: 24px;
          text-transform: uppercase;
          margin-top: 2rem;
          font-family: "ProximaNova-SemiBold";
        }

        .info-event-sub-heading {
          color: #999999;
          font-size: 25px;
          letter-spacing: 2px;
          text-transform: uppercase;
          line-height: 14px;
          font-family: "Proxima-Nova-Medium";
          margin-top: 1.5rem;
        }

        .info-event-details {
          font-family: "ProximaNova-Extra-Condensed-Bold";
          color: #ffffff;
          font-size: 50px;
          line-height: 35px;
          text-transform: uppercase;
          margin-top: 1.5rem;

          span {
            color: #c242a8;
            font-size: 30px;
            letter-spacing: 0.6px;
            text-transform: uppercase;
          }
        }

        .btn-event-wrapper {
          display: flex;
          flex-direction: row;
          gap: 41px;
          width: 100%;
          margin-top: 50px;

          .btn-event-buy {
            width: 220px;
            height: 60px;
            color: white;
            font-size: 18px;
            font-family: "Proxima-Nova-Medium";
            background: linear-gradient(
              to bottom,
              rgba(102, 14, 75, 1),
              rgba(69, 16, 54, 0.78)
            );
            text-transform: uppercase;
            border: 1px solid #999999;
            cursor: pointer;

            &:hover {
              background: linear-gradient(
                to bottom,
                rgba(255, 108, 226, 1),
                rgba(194, 66, 168, 0.78)
              );
            }
          }

          .btn-event-upcomig {
            width: 250px;
            height: 60px;
            font-size: 18px;
            font-family: "Proxima-Nova-Medium";
            color: #ffffff;
            background-image: linear-gradient(to top, #360d2a, #5c0e44);
            text-transform: uppercase;
            border: 1px solid #999999;
            cursor: pointer;

            &:hover {
              background-image: linear-gradient(to top, #d856be, #9c3687);
            }
          }
        }
      }
    }
  }

  .event-details {
    border-top: 1px solid #999999;

    .event-description {
      font-size: 20px;
      color: #ffffff;
      font-family: "Proxima-Nova-Medium";
      width: 800px;
      margin-left: 117px;
      margin-bottom: 84px;
      margin-top: 87px;
      padding-top: 0;
      line-height: 30px;

      span {
        color: #f567d9;
      }
    }
  }

  .line-wrapper {
    width: 100%;
    height: 17px;
    border-top: 1px solid #999999;
    border-bottom: 1px solid #999999;
  }

  .casting-wrapper {
    padding-left: 117px;

    .contentrail-wrapper {
      width: 100%;
      background-color: black;

      .swiper-slide {
        width: 250px !important;
      }

      .rail-title {
        font-size: 20px;
        color: #ffffff;
        letter-spacing: 1.6px;
        font-family: "ProximaNova-SemiBold";
        text-transform: uppercase;
        margin-top: 72px;
        margin-bottom: 55px;
        line-height: 30px;
      }
      .swiper-button-disabled {
        display: none;
      }
      .swiper-button-next {
        right: 0;
        background: linear-gradient(
          to left,
          rgba(0, 0, 0, 0.8),
          rgba(0, 0, 0, 0.4)
        );
      }
      .swiper-button-prev {
        left: 0;
        background: linear-gradient(
          to right,
          rgba(0, 0, 0, 0.8),
          rgba(0, 0, 0, 0.4)
        );
      }
      .swiper-button-next,
      .swiper-button-prev {
        height: 100%;
        padding: 0 10px;
        color: #999999;
        top: 0;
        &:hover {
          color: #c242a8;
        }
      }
    }
  }

  //  order-cart-pop-up
  .order-card-wrapper {
    background-color: #000000;
    position: absolute;
    top: 0px;
    right: -654px;
    z-index: 100000;
    width: 654px;
    height: auto;
    border-left: 1px solid #c242a8;
    animation: slideRightToLeft 0.5s forwards;

    @keyframes slideRightToLeft {
      from {
        right: -654px;
      }

      to {
        right: 0;
      }
    }

    .card-heading-warpper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 51px;
      padding: 0rem 3rem;

      .card-heading {
        font-size: 30px;
        color: #ffffff;
        font-family: "ProximaNova-Regular";
      }

      .cross-logo-wrapper {
        width: 20px;
        height: 20px;
        cursor: pointer;

        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .card-wrapper {
      width: 199px;
      height: 200px;
      margin-top: 168px;
      margin-left: 76px;

      img {
        width: 100%;
      }
    }

    .card-details-wrapper {
      width: 199px;
      margin-left: 64px;
      margin-top: 20px;
      text-align: center;

      .card-heading {
        font-size: 14px;
        color: #ffffff;
        font-family: "ProximaNova-Regular";
        margin-top: 5px;
      }

      .count-wrapper {
        color: #ffffff;
        font-size: 16px;
        font-family: "ProximaNova-SemiBold";
        background-color: #3e3e3e;
        border: #999999;
        width: 80px;
        height: 20px;

        span {
          color: #ffffff;
          font-size: 16px;
          font-family: "ProximaNova-SemiBold";
        }
      }
    }
  }

  .card-bill-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 260px;
    margin-bottom: 10px;
    padding: 0 3rem;
    border-bottom: 1px solid #c242a8;

    .bill-heading {
      color: #ffffff;
      font-size: 2rem;
      font-family: "ProximaNova-Regular";
      padding-bottom: 10px;
    }

    .bill-amount {
      color: #ffffff;
      font-size: 1.5rem;
      font-family: "ProximaNova-Regular";
      padding-bottom: 10px;
    }
  }

  .order-btn-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 19px;
    margin-top: 1rem;
    margin-bottom: 1rem;

    .btn-shopping {
      width: 260px;
      height: 60px;
      background-color: #676767;
      color: #ffffff;
      font-family: "ProximaNova-Regular";
      border: none;

      &:hover {
        background-image: linear-gradient(to top, #451036, #451036);
        border: 1px solid #999999;
      }
    }

    .btn-proceed {
      width: 260px;
      height: 60px;
      background-color: #ffffff;
      color: #000000;
      font-family: "ProximaNova-Regular";
      border: none;

      &:hover {
        background-image: linear-gradient(to top, #d856be, #9c3687);
        border: none;
      }
    }
  }
}

.ml-25 {
  margin-left: -25px;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .event-container-wrapper .heroContainer .imgCarousel .info-event-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 30px;
  }
  .event-container-wrapper
    .heroContainer
    .imgCarousel
    .info-event-wrapper
    .event-direction {
    margin-left: -30px !important;
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    width: 225px;
  }
  .event-container-wrapper
    .heroContainer
    .imgCarousel
    .info-event-wrapper
    .info-event-heading {
    margin-top: 95px;
    font-size: 45px;
    letter-spacing: -0.9px;
    line-height: 35px;
  }
  .event-container-wrapper
    .heroContainer
    .imgCarousel
    .info-event-wrapper
    .info-event-title {
    font-size: 18px;
    letter-spacing: 0.36px;
    line-height: 24px;
  }
  .event-container-wrapper
    .heroContainer
    .imgCarousel
    .info-event-wrapper
    .info-event-sub-heading {
    font-size: 14px;
    letter-spacing: 1.4px;
    line-height: 14px;
  }
  .event-container-wrapper
    .heroContainer
    .imgCarousel
    .info-event-wrapper
    .info-event-details {
    color: #ffffff;
    font-size: 30px;
    line-height: 35px;
    text-transform: uppercase;
  }
  .event-container-wrapper
    .heroContainer
    .imgCarousel
    .info-event-wrapper
    .btn-event-wrapper {
    display: flex;
    flex-direction: column;
    gap: 37px;
    width: 100%;
    margin-top: 37px;
    margin-bottom: 50px;
  }
  .event-container-wrapper .event-details .event-description {
    font-size: 20px;
    color: #ffffff;
    font-family: "Proxima-Nova-Medium";
    margin-left: 30px !important;
    margin-bottom: 80px;
    margin-right: 42px !important;
    margin-top: 54.5px;
    padding-top: 0;
    line-height: 30px;
    width: 80%;
  }
  .event-container-wrapper .casting-wrapper {
    padding-left: 30px;
  }
  .event-container-wrapper .casting-wrapper .contentrail-wrapper .swiper-slide {
    width: 200px !important;
  }
  .event-container-wrapper
    .heroContainer
    .imgCarousel
    .info-event-wrapper
    .line {
    width: 1rem;
    border-bottom: 2px solid #c242a8;
  }
  .line-doat {
    margin-left: 20px;
  }
  .event-container-wrapper
    .heroContainer
    .imgCarousel
    .info-event-wrapper
    .btn-event-wrapper
    .btn-event-buy {
    width: 175px;
    height: 50px;
  }
  .event-container-wrapper
    .heroContainer
    .imgCarousel
    .info-event-wrapper
    .btn-event-wrapper
    .btn-event-upcomig {
    width: 175px;
    height: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .event-container-wrapper
    .heroContainer
    .imgCarousel
    .info-event-wrapper
    .info-event-heading {
    margin-top: -29px;
  }
  .btn-event-wrapper .btn-event-buy {
    width: 200px;
    height: 55px;
  }
  .event-container-wrapper
    .heroContainer
    .imgCarousel
    .info-event-wrapper
    .btn-event-wrapper
    .btn-event-upcomig {
    width: 200px;
    height: 55px;
  }
  .event-container-wrapper
    .heroContainer
    .imgCarousel
    .info-event-wrapper
    .btn-event-wrapper
    .btn-event-buy {
    width: 200px;
    height: 55px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1900px) {
  .event-container-wrapper
    .heroContainer
    .imgCarousel
    .info-event-wrapper
    .info-event-heading {
    font-size: 80px;
    margin-top: 70px;
  }
  .event-container-wrapper
    .heroContainer
    .imgCarousel
    .info-event-wrapper
    .info-event-title {
    font-size: 25px;
    line-height: 16px;
  }
  .event-container-wrapper
    .heroContainer
    .imgCarousel
    .info-event-wrapper
    .info-event-sub-heading {
    font-size: 16px;
  }
  .event-container-wrapper
    .heroContainer
    .imgCarousel
    .info-event-wrapper
    .info-event-details {
    font-size: 40px;
  }
  .event-container-wrapper
    .heroContainer
    .imgCarousel
    .info-event-wrapper
    .btn-event-wrapper
    .btn-event-buy {
    width: 190px;
    height: 50px;
    font-size: 14px;
  }
  .event-container-wrapper
    .heroContainer
    .imgCarousel
    .info-event-wrapper
    .btn-event-wrapper
    .btn-event-upcomig {
    width: 190px;
    height: 50px;
    font-size: 14px;
  }
  .event-container-wrapper .event-details .event-description {
    width: 600px;
    margin-left: 117px;
    margin-bottom: 50px;
    margin-top: 50px;
  }
  .event-container-wrapper {
    padding-bottom: 100px;
  }
  .event-container-wrapper .event-details .event-description {
    font-size: 18px;
    line-height: 26px;
  }
}
