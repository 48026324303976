.faq-wrapper {
  width: 100%;
  background-color: #c242a8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 43px;
  background-image: linear-gradient(to top, #360d2a, #5c0e44);
  margin-bottom: 15px;

  .faq-main-heading {
    font-family: "Proxima-Nova-Medium";
    font-size: 18px;
    letter-spacing: 1.44px;
    line-height: 22px;
    color: #ffffff;
    text-align: center;
    margin-top: 56px;
    text-transform: uppercase;
  }

  .faq-heading {
    font-family: "Proxima-Nova-Medium";
    font-size: 22px;
    line-height: 28px;
    color: #ffffff;
  }

  .accordion {
    display: grid;
    gap: 36px;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 126px;
  }

  .faq-wrapper-inner {
    .inner-heading {
      color: #ffffff;
      font-family: "ProximaNova-Regular";
      padding: 10px;
      width: 800px;
      cursor: pointer;
    }
    .btn-more {
      cursor: pointer;
      width: 160px;
      height: 50px;
      font-family: "Proxima-Nova-Medium";
      color: #ffffff;
      font-size: 18px;
      text-transform: uppercase;
      background-color: #442139;
      display: block;
      margin-top: 13px;
      margin-bottom: 25px;
      border: 1px solid #999999;
      &:hover{
        background-color: #35182C;
        color: #c242a8;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .faq-wrapper .faq-main-heading {
    margin-top: 35.48px;
    font-size: 14px;
    letter-spacing: 1.12px;
    line-height: 17px;
  }
  .faq-wrapper {
    .inner-heading {
      width: 240px !important;
    }

    .accordion {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-bottom: 10px;
      padding-bottom: 1.5rem;
    }

    .accordion-item {
      width: 310px;
      min-height: 87px;
      margin: 0 auto;
    }
  }
  .faq-wrapper .faq-wrapper-inner .btn-more {
    width: 120px;
    height: 40px;
    font-size: 14px;
    &:hover{
      background-color: #35182C;
      color: #c242a8;
    }
  }
}

.accordion-wrapper {
  background-color: #ccc;
}

.accordion-item {
  border: 1px solid #707070;
  background-color: #6d315c;
  width: 375px;
  min-height: 87px;
}

.accordion-title {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0px 41px 0px 36px;
}

.accordion-content {
  padding-left: 28px;
  display: none;
  overflow: hidden;
  transition: max-height 0.1s ease;
}

.accordion-content.active {
  font-family: "Proxima-Nova-Medium";
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  display: block;
  max-height: 1000px;
  padding: 20px 36px;
}

.inner-heading {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 87px;
  align-items: center;
}
.icon-wrapper {
  width: 17px;
  height: 37px;
  margin-top: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .accordion-item {
    width: 213px;
    min-height: 60px;
  }

  .faq-wrapper .faq-heading {
    font-family: "Proxima-Nova-Medium";
    font-size: 14px;
    line-height: 1.1;
  }
  .accordion-content.active {
    font-size: 14px;
    line-height: 20px;
  }
  .faq-wrapper .faq-wrapper-inner .btn-more {
    width: 110px;
    height: 40px;
    font-size: 14px;
    &:hover{
      background-color: #35182C;
      color: #c242a8;
    }
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1900px) {
  .faq-wrapper .accordion {
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 56px;
  }
  .accordion-item {
    width: 290px;
    min-height: 57px !important;
  }
  .faq-wrapper .faq-heading {
    font-size: 18px;
    line-height: 18px;
  }
  .accordion-content.active {
    font-size: 16px;
    line-height: 20px;
  }
  .faq-wrapper .faq-wrapper-inner .btn-more {
    cursor: pointer;
    width: 130px;
    height: 40px;
    font-size: 14px;
    &:hover{
      background-color: #35182C;
      color: #c242a8;
    }
  }
}
