.heroContainer {
  // height: calc(100vh - 190px);
  width: 100%;
  position: relative;

  .info-wrapper {
    top: 0;
    left: 132px;
    max-width: 80vw;
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 2;
    bottom: 0px;
    justify-content: center;
    padding-bottom: 3vh;
    padding-top: 0vh;
    .info-title {
      &:empty {
        display: none;
      }
    }

    .info-wrapper-main-heading {
      font-family: 'ProximaNova-SemiBold';
      font-size: 30px;
      letter-spacing: 0.6px;
      line-height: 24px;
      color: #ff6ce2;
      text-transform: uppercase;
      margin-bottom: 0.8rem;
    }
    .info-title {
      font-family: 'ProximaNova-SemiBold';
      font-size: 30px;
      letter-spacing: 0.6px;
      line-height: 24px;
      color: #ff6ce2;
      text-transform: uppercase;
      margin-bottom: 1rem;
    }
    .info-heading {
      font-family: 'ProximaNova-Extra-Condensed-Bold';
      font-size: 100px;
      letter-spacing: -2px;
      line-height: 35px;
      color: #ffffff;
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-transform: uppercase;
    }
    .info-sub-heading {
      font-family: 'Proxima-Nova-Medium';
      font-size: 25px;
      line-height: 14px;
      letter-spacing: 2px;
      color: #e8e8e8;
      margin-top: 2rem;
      margin-bottom: 2rem;
      text-transform: uppercase;
      text-shadow: 2px 2px #000
    }
    .info-details {
      font-family: 'ProximaNova-Extra-Condensed-Bold';
      font-size: 50px;
      line-height: 35px;
      color: #ffffff;
      text-transform: uppercase;
      span {
        color: #c242a8;
      }
    }
    .btn-wrapper {
      margin-top: 30px;
      display: flex;
      flex-direction: column;

      .btn-buy {
        width: 220px;
        height: 60px;
        font-family: 'Proxima-Nova-Medium';
        font-size: 18px;
        color: #ffffff;
        cursor: pointer;
        text-align: center;
        text-transform: uppercase;
        background: linear-gradient(
          to bottom,
          rgba(102, 14, 75, 1),
          rgba(69, 16, 54, 0.78)
        );

        border: 1px solid #707070;
        &:hover {
          border: 1px solid #707070;
          background: linear-gradient(
            to bottom,
            rgba(255, 108, 226, 1),
            rgba(194, 66, 168, 0.78)
          );
        }

        &.greyBtn {
          background: rgb(51, 47, 52);
          cursor: pointer;
          border: none;
        }
      }
      .btn-info {
        width: 175px;
        height: 60px;
        cursor: pointer;
        font-family: 'Proxima-Nova-Medium';
        font-size: 18px;
        opacity: 65%;
        color: #ffffff;
        text-align: center;
        border: 1px solid #707070;
        background-color: #030b10;
        text-transform: uppercase;
        margin-top: 37px;
        &:hover {
          border: 1px solid #707070;
          background: linear-gradient(
            to bottom,
            rgba(255, 108, 226, 1),
            rgba(194, 66, 168, 0.78)
          );
        }
      }
    }
  }
  .Timer-count {
    font-family: 'ProximaNova-SemiBold';
    color: #ffffff;
    font-size: 90px;
    font-weight: 300;
    margin-top: 6rem;
  }
  .heroSwiper {
    width: 100%;
    height: inherit;
  }

  .heroSwiperSlide {
    position: relative;
    width: 100%;
    height: inherit;
  }

  .swiper-wrapper {
    height: inherit;
    position: relative;
    overflow: hidden;
  }
  .swiper-wrapper .swiper-slide {
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.9),
      rgba(255, 255, 255, 0)
    );
  }
  .swiper-wrapper .swiper-slide::after {
    background: linear-gradient(
      180deg,
      #00000026 20%,
      #000 0,
      #00000059 0,
      #00000059 40%,
      #0009 0,
      #000000a6 60%,
      #000000d9 0,
      #000000d9 80%,
      #000 0,
      #000
    );
    bottom: 0;
    content: '';
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
    height: 120px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  .imgCarousel {
    width: 100%;
    height: 100%;
    &:before {
      background: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, #000 100%);
      content: "";
      left: 0px;
      position: absolute;
      top: 0px;
      width: 30%;
      height: 100%;
      z-index: 2;
    }
    // background: linear-gradient(
    //   to right,
    //   rgba(0, 0, 0, 0.99),
    //   rgba(255, 255, 255, 0)
    // );
    img {
      width: 100%;
      height: 100%;
    }
  }
  .swiper-img {
    height: auto;
    z-index: 0;
    // position: fixed;
    object-position: center -2vh;
    object-fit: contain;
  }
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .event-container-wrapper {
    .heroContainer {
      height: 655px;
      width: 100%;
      background-color: black;
    }
  }
  .heroContainer {
    height: 380px;
    width: 100%;
    background-color: black;
  }
  .heroContainer .info-wrapper {
    // top: 4rem;
    left: 2rem;
    padding-bottom: 50px;
  }
  .heroContainer .imgCarousel img {
    object-fit: cover;
  }
  .heroContainer .info-wrapper .info-wrapper-main-heading {
    font-size: 18px;
    line-height: 1.6;
    text-shadow: 2px 2px #000
  }
  .heroContainer .info-wrapper .info-heading {
    font-size: 32px;
    margin-top: 0px;
    line-height: 1.6;
    margin-bottom: 0;
    letter-spacing: -1px;
    text-shadow: 2px 2px #000
  }
  .heroContainer .info-wrapper .info-sub-heading {
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 1.6;
    letter-spacing: 1.6px;
  }
  .heroContainer .info-wrapper .info-details {
    font-size: 20px;
    line-height: 1.6;
    text-shadow: 2px 2px #000
  }
  .heroContainer .info-wrapper .btn-wrapper {
    margin-top: 25px;
  }
  .heroContainer .info-wrapper .btn-wrapper .btn-buy {
    width: 180px;
    height: 50px;
    font-size: 14px;
  }
  .heroContainer .info-wrapper .btn-wrapper .btn-info {
    width: 150px;
    height: 50px;
    font-size: 14px;
    margin-top: 20px;
  }
  .heroContainer .swiper-wrapper .swiper-slide::after {
    background: linear-gradient(
      180deg,
      #00000026 20%,
      #000 0,
      #00000059 0,
      #00000059 40%,
      #0009 0,
      #000000a6 60%,
      #000000d9 0,
      #000000d9 80%,
      #000 0,
      #000
    );
    bottom: 0;
    content: '';
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
    height: 100px !important;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .heroContainer .info-wrapper .info-wrapper-main-heading {
    font-size: 25px;
    letter-spacing: 0.6px;
    line-height: 10px;
  }
  .heroContainer .info-wrapper .info-heading {
    font-size: 60px;
    letter-spacing: -2px;
    line-height: 30px;
    margin-top: 1rem;
    margin-bottom: 0rem;
  }
  .heroContainer .info-wrapper .info-sub-heading {
    font-size: 16px;
    line-height: 10px;
    letter-spacing: 2px;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .heroContainer .info-wrapper .info-details {
    font-size: 30px;
    line-height: 30px;
  }
  .heroContainer .info-wrapper .btn-wrapper .btn-buy {
    width: 170px;
    height: 50px;
  }
  .heroContainer .info-wrapper .btn-wrapper .btn-info {
    width: 140px;
    height: 50px;
    margin-top: 30px;
  }
  .swiper-img {
    object-fit: cover;
    width: 100%;
    height: 100vh;
    object-position: center center;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1900px) {
  .heroContainer {
    // height: calc(100vh - 90px);
    width: 100%;
    position: relative;
  }
  .heroContainer .info-wrapper {
    // top: 110px !important;
  }
  .heroContainer .info-wrapper .info-wrapper-main-heading {
    font-size: 25px;
    text-shadow: 2px 2px #000
  }
  .heroContainer .info-wrapper .info-heading {
    font-size: 70px;
    text-shadow: 2px 2px #000
  }
  .heroContainer .info-wrapper .info-sub-heading {
    font-size: 16px;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
  .heroContainer .info-wrapper .info-details {
    font-size: 40px;
    line-height: 20px;
    text-shadow: 2px 2px #000
  }
  .heroContainer .info-wrapper .btn-wrapper .btn-buy {
    width: 200px;
    height: 50px;
  }
  .heroContainer .info-wrapper .btn-wrapper .btn-info {
    width: 150px;
    height: 50px;
  }
  .swiper-img {
    object-fit: cover;
    width: 100%;
    height: 100vh;
    object-position: center center;
  }
  .heroContainer .swiper-wrapper .swiper-slide::after {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1490196078) 20%, #000 0, rgba(0, 0, 0, 0.3490196078) 0, rgba(0, 0, 0, 0.3490196078) 40%, rgba(0, 0, 0, 0.6) 0, rgba(0, 0, 0, 0.6509803922) 60%, rgba(0, 0, 0, 0.8509803922) 0, rgba(0, 0, 0, 0.8509803922) 80%, #000 0, #000);
    bottom: 0;
    content: "";
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
    height: 150px !important;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
}
}
