.Main-conatiner {
  width: 100%;
  height: auto;
}

.home-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  // height: 12.5rem;
  background-color: #660e4b;
  gap: 0.5rem;
  border-bottom: 1px solid #707070;
  font-family: ProximaNova-semiBold, Proxima Nova;
  font-weight: 600;
  font-size: 1.75rem;
  color: white;
  flex-direction: column;
  max-width: 100%;
  max-height: fit-content;
  .banner-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    div {
      text-align: center;
    }
  }
}
.home-banner-b {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 12.5rem;
  gap: 0.5rem;
  padding: 2rem;
  border-top: 1px solid #707070;
  // border-bottom: 1px solid #707070;
  font-family: ProximaNova-semiBold, Proxima Nova;
  font-weight: 600;
  font-size: 1.75rem;
  color: white;
  flex-direction: column;
  max-width: 100%;
  max-height: fit-content;
  .banner-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.landing-info-wrapper {
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;

  .info-wrapper-heading {
    font-family: 'ProximaNova-SemiBold';
    letter-spacing: 2.4px;
    font-size: 50px;
    line-height: 53px;
    color: #ffffff;
    text-align: center;
  }
  .info-wrapper-title {
    font-family: 'ProximaNova-Regular';
    font-size: 30px;
    letter-spacing: 2.4px;
    line-height: 53px;
    color: #ffffff;
    text-align: center;
    margin-top: 1rem;
    span {
      color: #ff6ce2 !important;
      font-size: 30px;
      line-height: 63px;
      font-family: 'ProximaNova-SemiBold';
    }
  }
  .info-wrapper-sub-heading {
    font-family: 'ProximaNova-SemiBold';
    font-size: 30px;
    letter-spacing: 2.4px;
    line-height: 53px;
    color: #ff6ce2;
    text-align: center;
    text-align: center;
    text-transform: uppercase;
    margin-top: 1rem;
  }
  .sub-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    .btn-event {
      margin-top: 63px;
      margin-bottom: 47.5px;
      width: 220px;
      height: 60px;
      font-family: 'Proxima-Nova-Medium';
      font-size: 18px;
      color: #ffffff;
      text-transform: uppercase;
      cursor: pointer;
      background-color: #262626;
      text-transform: uppercase;
      border: 1px solid #707070;
      background-image: linear-gradient(to top, #360d2a, #5c0e44);
      &:hover {
        border: 1px solid #707070;
        background-image: linear-gradient(to top, #f567d9, #f567d9);
      }
    }
    .v-logo {
      width: 33px;
      height: 28px;
      img {
        width: 100%;
      }
    }
  }
  .watch-wrapper {
    width: 100%;
    margin-top: 108.53px;
    img {
      width: 100%;
      cursor: pointer;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  
  .home-banner {
    // height: 5rem;
    padding: 20px;
  }
  .home-banner-b {
    height: unset;
    padding: 1rem;
  }
  .home-banner .banner-content {
    max-width: fit-content;
    max-height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-weight: 600;
    font-size: calc(1 * (1vh + 1vw));
  }
  .home-banner-b .banner-content {
    max-width: fit-content;
    max-height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-weight: 600;
    margin-top: 0px;
    font-size: 18px;
    // font-size: calc(1 * (1vh + 1vw));
  }
  .landing-info-wrapper {
    padding: 0rem 0;
  }
  .landing-info-wrapper .info-wrapper-heading {
    font-size: 1.4rem;
    font-family: 'ProximaNova-SemiBold';
    line-height: 1.2;
    letter-spacing: 1.4px;
  }
  .landing-info-wrapper .wrapper-title {
    font-family: 'ProximaNova-Regular';
    color: #ffffff;
    font-size: 24px;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 2.4px;
  }
  .landing-info-wrapper .info-wrapper-title span {
    display: none;
  }
  .landing-info-wrapper .info-wrapper-title {
    font-size: 20px;
    font-family: 'ProximaNova-Regular';
    padding: 0px 95px;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    line-height: 1.6;
  }
  .landing-info-wrapper .info-wrapper-sub-heading {
    font-size: 20px;
    line-height: 1.6;
    font-family: 'ProximaNova-SemiBold';
    margin-bottom: 1rem;
    margin-top: 0rem;
  }
  .landing-info-wrapper .sub-wrapper .btn-event {
    margin-top: 0px;
    width: 150px;
    height: 50px;
    font-size: 14px;
    margin-bottom: 30px;
  }
  .landing-info-wrapper .watch-wrapper {
    width: 100%;
    height: auto;
    margin-top: 0px;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .landing-info-wrapper .watch-info {
    margin-bottom: 0px;
  }
  .landing-info-wrapper .sub-wrapper .v-logo {
    width: 33px;
    height: 28px;
    margin-bottom: 56.52px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .home-banner {
    // height: 8rem;
  }
  .home-banner-b {
    // height: 8rem;
  }
  .home-banner .banner-content {
    max-width: fit-content;
    max-height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-weight: 600;
    font-size: calc(1 * (1vh + 1vw));
  }
  .home-banner-b .banner-content {
    max-width: fit-content;
    max-height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-weight: 600;
    font-size: calc(1 * (1vh + 1vw));
  }
  .landing-info-wrapper .info-wrapper-heading {
    letter-spacing: 1.4px;
    font-size: 35px;
    line-height: 35px;
  }
  .landing-info-wrapper .info-wrapper-title {
    font-size: 20px;
    letter-spacing: 1.4px;
    line-height: 20px;
  }
  .landing-info-wrapper .info-wrapper-sub-heading {
    font-size: 20px;
    letter-spacing: 1.4px;
    line-height: 20px;
  }
  .landing-info-wrapper .sub-wrapper .btn-event {
    margin-top: 50px;
    margin-bottom: 40.5px;
    width: 190px;
    height: 50px;
  }
  .landing-info-wrapper .watch-wrapper {
    width: 100%;
    margin-top: 90.53px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1900px) {
  .landing-info-wrapper .info-wrapper-heading {
    font-size: 2rem;
    line-height: 2.5rem;
  }
  .landing-info-wrapper .watch-wrapper {
    width: 100%;
    height: auto;
    margin-top: 80px;
  }
  .landing-info-wrapper .info-wrapper-title {
    font-size: 20px;
    letter-spacing: 2.4px;
    line-height: 20px;
    margin-top: 0rem;
  }
  .landing-info-wrapper .info-wrapper-sub-heading {
    font-size: 17px;
    letter-spacing: 2.4px;
    line-height: 17px;
  }
  .landing-info-wrapper .sub-wrapper .btn-event {
    width: 180px;
    height: 50px;
    font-size: 14px;
  }
}


@media (max-width: 1200px) {
  .home-banner-b {
    margin-top: 25px;
  }
}

@media (max-width: 992px) {
  .home-banner-b {
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .home-banner-b {
    margin-top: 15px;
  }
}

@media (max-width: 576px) {
  .home-banner-b {
    margin-top: 10px;
  }
}