// create account
.main-container {
  width: 24rem;
  height: 42rem;
  margin: 0 auto;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  position: absolute;
  top: 15%;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;

  .container-heading-sign {
    text-align: left;
    color: #ffffff;
    margin-left: -11rem;
    font-size: 30px;
    font-family: "ProximaNova-SemiBold";
  }
  .container-heading {
    color: #ffffff;
    text-align: center;
    font-weight: bold;
    line-height: 45px;
    word-spacing: 0.3px;
    font-size: 30px;
    width: 381px;
    height: 37px;
  }
  // .container-description {
  //   color: grey;
  //   font-size: 16px;
  //   line-height: 22px;
  //   font-family: 'ProximaNova-Semibold';
  //   text-align: center;
  //   width: 343px;
  //   height: 65px;
  // }
  .option-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    .btn-with {
      width: 270px;
      height: 47px;
      background-color: #c244a8;
      color: #ffffff;
      font-family: "ProximaNova-Semibold";
      text-align: center;
      border: 1px solid #d0d0d0;
      cursor: pointer;
      &:hover {
        background-color: #fe6ce1;
        color: #ffffff;
        border: 1px solid #d0d0d0;
      }
    }
    .option-wrapper-text {
      text-align: right;
      align-self: flex-end;
      color: gray;
      font-size: 12px;
      cursor: pointer;
      font-family: "ProximaNova-Regular";
    }
  }
  .devide-lines {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 255px;
    height: 24px;
    font-family: "ProximaNova-Regular";
    font-size: 16px;
    hr {
      width: 7rem;
      color: gray;
      border-bottom: 1px solid #d0d0d0;
    }
    span {
      text-decoration: none;
      font-family: "ProximaNova-Bold";
      color: gray;
      font-size: 15px;
      padding-bottom: 5px;
    }
  }
  .fill-box {
    display: flex;
    flex-direction: column;
    .fill-box-heading {
      color: #ffffff;
      font-family: "ProximaNova-Regular";
      font-size: 18px;
      width: 212px;
      height: 34px;
    }
    input {
      cursor: pointer;
      width: 100%;
      background-color: rgb(179, 179, 179);
      padding: 10px;
      line-height: 45px;
      font-family: "ProximaNova-Regular";
      font-size: 16px;
      color: #ffffff;
      margin-top: 10px;
      box-sizing: border-box;
      border: 1px solid #d0d0d0;
      width: 270px;
      height: 47px;
      outline: none;
    }
  }
  .sub-description {
    font-size: 16px;
    color: #999999;
    text-align: center;
    width: 343px;
    height: 65px;
    margin: 1rem 0;
    cursor: pointer;
    span {
      text-decoration: none;
      font-family: "ProximaNova-Bold";
      font-size: 16px;
      color: #ffffff;
      // font-weight: bold;

      a {
        color: white;
        text-decoration: none;
      }
    }
    ul {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      text-align: left;
      margin: 0 0 2rem 2rem;
    }
    li {
      color: #ffffff !important;
    }
    li::marker {
      font-size: 1.5rem;
    }
  }
  .sub-description-pass {
    font-family: "ProximaNova-Regular";
    font-size: 16px;
    color: gray;
    text-align: center;
    // width: 343px;
    height: 90px;
    margin: 1.5rem 0;
    cursor: pointer;
    span {
      text-decoration: none;
      font-family: "ProximaNova-Bold";
      font-size: 16px;
      // font-weight: bold;
      color: #ffffff;
      a {
        color: white;
        text-decoration: none;
      }
    }
    ul {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      text-align: left;
    }
    li {
      color: #ffffff !important;
    }
    li::marker {
      font-size: 1.5rem;
    }
  }
  .hover-text {
    color: gray;
    width: 366px;
    height: 23px;
    text-align: center;
    span {
      text-decoration: none;
      font-family: "ProximaNova-Bold";
      color: purple;
      // font-weight: bold;
    }
  }
  .main-continer-check-feild {
    align-self: flex-start;
    margin-left: 5rem;
    cursor: pointer;
    .box {
      transform: scale(1.5);
      margin-right: 10px;
      color: gray;
      background-color: gray;
      border: 1px solid #d0d0d0;
      cursor: pointer;
    }
    label {
      font-family: "ProximaNova-Regular";
      color: gray;
      cursor: pointer;
    }
  }
  .create-password-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    .sub-description-pass {
      font-size: 16px;
      color: gray;
      text-align: center;
      width: 343px;
      height: 65px;
      margin: 1.5rem 0;
      cursor: pointer;
      span {
        text-decoration: none;
        font-family: "ProximaNova-Bold";
        font-size: 16px;
        // font-weight: bold;
        color: #ffffff;
        a {
          color: white;
          text-decoration: none;
        }
      }
      ul {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: left;
      }
      li {
        color: #ffffff !important;
      }
      li::marker {
        font-size: 1.5rem;
      }
    }
  }
}
