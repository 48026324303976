.agreement-wrapper {
  padding-left: 117px;
  padding-right: 117px;
  padding-right: 100px;
  background-color: black;
  color: white;
  min-height: 600px;
  word-wrap: break-word;
  // text-align: center;
  // width: 100%;
  h1 {
    color: #c242a8;
    text-align: center;
    font-family: "ProximaNova-SemiBold";
  }
  .agrrment-description {
    width: 100%;
    color: white;
    font-size: 16px;
    font-family: "Proxima-Nova-Medium";
    line-height: 18px;
    letter-spacing: 1.3px;
    margin: 0;
    word-wrap: break-word;
  }
}
p {
  margin: 0;
  margin: 10px 0px;
  width: 100%;
  padding: 0px 5px;
}

@media only screen and (min-width: 320px) and (max-width: 650px) {
  .agreement-wrapper {
    padding-left: 11px;
    padding-right: 11px;
    background-color: black;
    color: white;
    // min-height: 600px;
    // width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .agrrment-description {
    margin: 0 auto;
    padding: 0px 11px;
  }
  p {
    margin: 0;
    margin: 10px 0px;
    padding: 0px 5px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
}
@media only screen and (min-width: 1024px) and (max-width: 1900px) {
}
