.videoPlayerContainer {
  background-color: black;
  width: 100vw;
  height: 98vh;
  display: flex;
  overflow: hidden;
  margin-top: 0px;
  position: fixed;
  * {
    box-sizing: border-box;
  }
  ul {
    margin: 0px;
    padding: 0px;
  }
  ul {
    list-style: none;

    li {
      list-style: none;
      color: white;
      // display: none;
    }
  }
  .powerd-by {
    display: flex;
    flex-direction: column;
    align-items: center;
    .pb-text {
      font-size: 10px;
      color: #999999;
      letter-spacing: 0.85px;
      margin-bottom: 30px;
    }
    img {
      width: 200px;
    }
  }
  .backIcon {
    cursor: pointer;
    width: 29.64px;
    height: 29.98px;
    position: relative;
    top: 21px;
    fill: gray;
    z-index: 999999;

    &:hover {
      fill: #ff6ce2;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
  a:hover {
    color: #ff6ce2;
  }
  .static_page_container {
    width: 80vw;
    height: 86%;
    position: relative;
    font-family: 'ProximaNova-SemiBold';
    background: url('../../assets/VOLTA-Slate.png') no-repeat;
    .static_page_content {
      font-size: 50px;
      color: #ff6ce2;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 39%;

      .page_title {
        position: relative;
        font-size: 23px;
        bottom: 180px;
        letter-spacing: 0.6px;
      }

      .asset_title {
        position: relative;
        top: -111px;
        font-size: 43px;
        line-height: 35px;
        letter-spacing: -1px;
        text-transform: uppercase;
        color: white;
        padding-bottom: 40px;
        border-bottom: 1px solid #ff6ce2;
      }

      .present_txt {
        font-size: 10px;
        color: #999999;
        letter-spacing: 0.85px;
        position: relative;
        bottom: 70px;
      }

      .logo {
        position: relative;
        bottom: 46px;
      }

      .cta_btn {
        position: relative;
        top: 43px;
        width: 200px;
        height: 50px;
        font-size: 14px;
        font-family: 'Proxima-Nova-Medium';
        color: #ffffff;
        background-image: linear-gradient(to top, #360d2a, #5c0e44);
        text-transform: uppercase;
        border: 1px solid #999999;
        &:hover {
          cursor: pointer;
          background-image: linear-gradient(to top, #d856be, #9c3687);
        }
      }

      .enjoy_txt {
        user-select: none;
        font-size: 20px !important;
        position: absolute;
        top: 347px;
        font-size: 38px;
        text-align: center;
        color: #999999;
        .letUs_txt {
          // cursor: pointer;
          color: white;
          text-decoration: none;
          // &:hover {
          //   color: #ff6ce2;
          // }
        }
      }
    }
  }

  .icon {
    width: 28px;
    height: 28px;
    padding: 10px;
    background-size: cover;
    margin: 0px 13px;
    cursor: pointer;
  }

  .icon-small {
    width: 28px;
    height: 28px;
    background-size: cover;
    margin: -15px 8px 0px;
    cursor: pointer;
  }

  .videoPlayerTop {
    display: flex;
    flex-direction: row;
    .leftMenuContainer {
      width: 73px;
      height: calc(100vh - 10%);
      text-align: left;
      padding-left: 34px;
      // display: none;
      .menuLogo {
        width: 30.65px;
        margin-top: 27px;
        margin-left: 0;
        margin-right: 41.2px;
      }
    }
    .playerContainer {
      position: relative;
      top: 26px;
      left: 100px;
      .soloVideoViewContainer {
        // width: calc(100vw - 241px);
        // height: calc(100vh - 140px);
        width: 100%; /* Set the container width to 100% */
        height: auto; /* Allow the container height to adjust based on the video's aspect ratio */
        /* Add any other styles you need for the video container */

        &.fullScreen {
          // width: 100vw;
          // height: 100vh;
          // max-width: 100%;
          position: fixed;
          top: 0px;

          width: 100%; /* Set the container width to 100% */
          height: auto; /* Allow the container height to adjust based on the video's aspect ratio */
        }

        .videoContainer {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
        .audioContainer {
          display: none;
        }
      }

      .SolomediaDetailOverlay {
        position: absolute;
        display: none;
        height: 100%;
        width: 100%;
        bottom: 0px;
        pointer-events: none;
        background-color: #00000080;
        // .topLine {
        //   width: 160vh;
        //   border: 2px solid #f76ce2;
        //   position: absolute;
        //   top: -26px;
        //   left: -19px;
        // }
        .videoTitle {
          padding-top: 15px;
          display: block;
          padding-left: 15px;
          color: #f76ce2;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 16px;
          position: static;
          white-space: nowrap;
        }
        .overlayLogo {
          position: absolute;
          left: 140vh;
          top: 79vh;
        }
      }

      // .mediaDetailOverlay {
      //   .topLine {
      //     position: absolute;
      //     top: 1px;
      //     width: 100vw;
      //     border: 2px solid #ff6ce2;
      //   }
      //   .videoTitle {
      //     position: absolute;
      //     top: 100px;
      //     left: 100px;
      //   }
      //   .overlayLogo {
      //   }
      //   &.fullscreen {
      //     .topLine {
      //       display: none;
      //     }
      //     .videoTitle {
      //       position: absolute;
      //       color: #ff6ce2;
      //       font-family: 'ProximaNova-Regular';
      //       text-transform: uppercase;
      //       top: 100px;
      //       left: 100px;
      //       font-size: x-large;
      //       font-weight: 600;
      //       letter-spacing: 1.5px;
      //     }
      //     .overlayLogo {
      //       height: 100px;
      //       position: absolute;
      //       bottom: 15%;
      //       right: 10%;
      //     }
      //   }
      // }
      .threeByThreeVideoViewContainer {
        &.mosaic {
          display: grid;
          gap: 1px;
          grid-template-columns: repeat(3, 1fr);
        }
        &.fourboxview {
          display: grid;
          gap: 1px;
          grid-template-columns: repeat(2, 1fr);
        }
        &.corners {
          .repeat-video {
            &:nth-child(1),
            &:nth-child(4) {
              width: 60%;
              display: inline-block;
              vertical-align: top;
            }
            &:nth-child(2),
            &:nth-child(3) {
              width: 34%;
              display: inline-block;
              margin: 3%;
              vertical-align: top;
            }
            &:nth-child(4) {
              margin-top: -12%;
            }
          }
        }
        &.twotiltedview {
          .repeat-video {
            &:nth-child(1),
            &:nth-child(2) {
              width: 60%;
              display: inline-block;
              vertical-align: top;
            }

            &:nth-child(2) {
              margin-top: -12%;
            }
          }
        }
        .repeat-video {
          position: relative;
          &:hover {
            .mediaDetailOverlay {
              display: block;
            }
          }
          .mediaDetailOverlay {
            position: absolute;
            display: none;
            height: 100%;
            width: 100%;
            bottom: 0px;
            background-color: #00000080;
            .topLine {
              width: 100%;
            }
            .videoTitle {
              padding-top: 15px;
              display: block;
              padding-left: 15px;
              color: #f76ce2;
              text-transform: uppercase;
              font-weight: 600;
              font-size: 16px;
              position: static;
            }
            .overlayLogo {
              position: absolute;
              right: 20px;
              bottom: 20px;
            }
          }
        }
      }
    }

    .rightMenuContainer {
      width: 50px;
      height: 95vh;
      display: flex;
      flex-direction: column;
      justify-content: end;
      align-items: center;
      position: absolute;
      right: 0px;
      background-color: black;
      text-align: center;
      padding-bottom: 160px;
      .rightMenuTop {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
      }

      .divider {
        width: 40px;
        margin-left: 15px;
        border: 1.5px solid #3e3e3e;
        margin-bottom: 15px;
      }
      .rightMenuBottom {
        ul {
          padding-left: 0px !important;
          li {
            text-align: center;
          }
          .closeicon {
            display: none;
          }
          .player-icon {
            cursor: pointer;
            width: 29.64px;
            height: 29.98px;

            &:hover {
              .st1 {
                fill: #ff6ce2;
              }
              text {
                fill: #ff6ce2;
                stroke: #ff6ce2;
              }
              g rect {
                fill: #ff6ce2;
              }
            }

            &.activeElement {
              .st1 {
                fill: #ff6ce2;
              }
              text {
                fill: #ff6ce2;
                stroke: #ff6ce2;
              }
              g rect {
                fill: #ff6ce2;
              }
            }
          }
        }
        .chatPopup {
          position: absolute;
          bottom: 470px;
          right: 150px;
          width: 150px;
          height: 200px;
          background-color: #3e3e3e;
        }
        .castPopup {
          position: absolute;
          bottom: 350px;
          right: 200px;
          width: 150px;
          height: 200px;
          background-color: #3e3e3e;
        }
      }
    }
  }

  .videoPlayerBottom {
    width: 99.99%;
    padding: 0 40px;
    // height: 10%;
    position: absolute;
    padding-top: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    background-color: black;

    .horiMenuImg {
      cursor: pointer;
      width: 29.64px;
      height: 29.64px;
    }

    .verticalLine {
    }

    .bottomMenuTop .progressBarContainer {
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;

      .progressSlider {
        height: 4.22px;

        &::-webkit-slider-thumb {
          width: 9px;
          height: 10px;
          color: #ff6ce2;
        }

        &::-moz-range-thumb {
          width: 9px;
          height: 10px;
          color: #ff6ce2;
        }
      }
      .progressImage {
        margin-top: 10px;
      }
    }
    // .bottomMenuBottom {
    //   display: flex;
    //   .bottomLeft {
    //     display: flex;
    //     width: 35%;
    //     position: relative;
    //     left: 50px;
    //     justify-content: space-evenly;
    //     .volumeSlider {
    //     }
    //   }
    //   .bottomCenter {
    //     width: 20%;
    //     display: flex;
    //     position: relative;
    //     left: 197.88px;
    //     right: 161.66px;
    //     .videoTitle {
    //     }
    //   }
    //   .bottomRight {
    //     display: flex;
    //     justify-content: space-evenly;
    //     align-items: center;
    //     position: relative;
    //     width: 25%;
    //     left: 130px;
    //     top: -20px;
    //     .activePlayerViewTitle {
    //       color: #ff6ce2;
    //       font-family: "ProximaNova-Regular";
    //       font-size: 18px;
    //       line-height: 22px;
    //       letter-spacing: 1px;
    //     }

    //     .playerViewModeContainer {
    //       display: none;
    //       .dropdown {
    //         position: absolute;
    //         bottom: 0px;
    //         min-width: 200px;
    //         .dropdownMenu {
    //           position: absolute;
    //           bottom: 100px;
    //           min-width: 200px;
    //           right: 82px;
    //           background: #ffffff20;
    //           border-radius: 10px;
    //           padding-right: 30px;
    //           &:after {
    //             content: "";
    //             margin-left: 111px;
    //             margin-bottom: -20px;
    //             position: absolute;
    //             border-left: 20px solid #ffffff20;
    //             border-right: 20px solid #ffffff20;
    //             // border-top: 20px solid #ffffff20;
    //           }
    //           li {
    //             a {
    //               display: flex;
    //               align-items: center;
    //               width: 182px;
    //               justify-content: space-between;
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    .bottomMenuBottom {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin: auto;
      align-items: center;
      .bottomLeft {
        display: flex;
        // width: 26%;
        position: relative;
        // left: 50px;
        min-width: 300px;

        justify-content: space-evenly;
        align-items: center;
        li {
          margin-right: 15px;
        }
        li.player-playpause, .player-forward, .player-rewind {
          
          &:hover {
            svg {
              stroke: #fe6ce2;
            }
          }
        }
        .player-icon {
          cursor: pointer;
          width: 29.64px;
          height: 29.98px;
        }
        .player-icon:hover {
          .st1 {
            fill: #ff6ce2;
          }
          text {
            fill: #ff6ce2;
            stroke: #ff6ce2;
          }
          g rect {
            fill: #ff6ce2;
          }
        }
        .volumeSlider {
        }
      }
      .bottomCenter {
        // width: 30%;
        display: flex;
        position: relative;
        // left: 197.88px;
        // right: 161.66px;
        .videoTitle {
          // padding-right: 20px;
          text-transform: uppercase;
          font-size: large;
        }
      }
      .bottomRight {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        min-width: 300px;
        gap: 15px;
        // width: 23%;
        // left: 130px;
        // top: -20px;
        .activePlayerViewTitle {
          color: #ff6ce2;
          font-family: 'ProximaNova-Regular';
          font-size: 18px;
          line-height: 22px;
          letter-spacing: 1px;
        }

        .playerViewModeContainer {
          // display: none;
          .dropdown {
            position: absolute;
            bottom: 0px;
            min-width: 200px;
            .dropdownMenu {
              position: absolute;
              bottom: 100px;
              min-width: 200px;
              right: 110px;
              background: #ffffff10;
              padding-right: 10px;
              padding-left: 30px;
              z-index: 1;
              &:after {
                content: '';
                margin-left: 111px;
                margin-bottom: -20px;
                position: absolute;
                height: 20px;
                border-color: #ffffff10 transparent transparent transparent;
                border-width: 13px 10px 10px 10px;
                border-style: solid;
              }
              li {
                a {
                  display: flex;
                  align-items: center;
                  width: 182px;
                  // justify-content: space-between;
                  .view-name {
                    width: 112px;
                    text-align: right;
                  }
                }
              }
            }
          }
        }
        .player-icon {
          cursor: pointer;
          width: 50px;
          height: 50px;
          // margin-top: 10px;
          &.fullicon {
            width: 29.64px;
            height: 29.98px;
          }
        }
        .player-icon:hover {
          // width: 20px;
          // height: 20px;
          // padding-top: 5px;
          path {
            fill: #ff6ce2;
          }
          text {
            fill: #ff6ce2;
            stroke: #ff6ce2;
          }
          g rect {
            // fill: #ff6ce2;
          }
          g polygon {
            fill: #ff6ce2;
          }
        }
      }
    }
  }
  @supports (hanging-punctuation: first) and (font: -apple-system-body) and (-webkit-appearance: none) {

    
      input[type=range] {
        -webkit-appearance: none; /* Remove default styling */
        height: 8px; /* Set the height of the track */
        background: transparent; /* Track color */
        outline: none; /* Remove outline */
        opacity: 0.7; /* Set transparency */
        transition: opacity 0.2s; /* Smooth transition on hover */
        accent-color: #fe6ce2;
      }
    
      /* Hover effect */
      input[type=range]:hover {
        opacity: 1;
      }
    
      /* Customize the thumb */
      input[type=range]::-webkit-slider-thumb {
        -webkit-appearance: none; /* Remove default thumb styling */
        appearance: none;
        width: 16px; /* Thumb width */
        height: 16px; /* Thumb height */
        background: #fe6ce2; /* Thumb color */
        cursor: pointer; /* Pointer cursor on hover */
        border-radius: 50%; /* Rounded thumb */
        margin-top: -4px;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5); /* Thumb shadow */
      }
    
      /* Customize the track */
      input[type=range]::-webkit-slider-runnable-track {
        // width: 100%;
        // background: linear-gradient(to right, #4CAF50 0%, #4CAF50 var(--value, 50%), #ddd var(--value, 50%), #ddd 100%);
        height: 8px;
        cursor: pointer;
        background: #636363;
        border-radius: 20px;
        // box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
      }
    
      .player-volume {
        input[type=range] {
          height: 8px; /* Set the height of the track */
          top: -10px !important;
        }
      
        /* Hover effect */
        input[type=range]:hover {
          opacity: 1;
        }
      
        
        /* Customize the thumb */
        input[type=range]::-webkit-slider-thumb {
          background: #fe6ce2; /* Thumb color */
          margin-top: -4px;
        }
      
        /* Customize the track */
        input[type=range]::-webkit-slider-runnable-track {
          top: -12px !important;
          height: 8px;
          
        }
      }
      .progressBarContainer {
        input[type=range] {
          height: 6px !important; /* Set the height of the track */
        }
      
        /* Hover effect */
        input[type=range]:hover {
          opacity: 1;
        }
      
        /* Customize the thumb */
        input[type=range]::-webkit-slider-thumb {
          background: #fe6ce2; /* Thumb color */
          margin-top: -5px;
          width: 16px !important; /* Thumb width */
        height: 16px !important; /* Thumb height */
        }
      
        /* Customize the track */
        input[type=range]::-webkit-slider-runnable-track {
          // width: 100%;
          height: 6px;
        }
      }
    
  }

  
}

.qualityContainer {
  
  .quality-icon {
    width: 45px;
    height: 45px;
    cursor: pointer;
    fill: #999;
    &:hover {
      fill: #ff6ce2;
    }
  }
  .qualityDropDownContainer {
    position: absolute;
    bottom: 88px;
    right: 30px;
    background: rgba(255, 255, 255, 0.062745098);
    padding-right: 10px;
    padding-left: 10px;
    z-index: 1;
    border: none;
    border-radius: 5px;
    &:after {
      content: '';
      margin-left: 50px;
      margin-bottom: -20px;
      position: absolute;
      height: 20px;
      border-color: #ffffff10 transparent transparent transparent;
      border-width: 13px 10px 10px 10px;
      border-style: solid;
    }
    .qualityDropDownItem {
      color: #ffffff;
      font-size: 1rem;
      padding: 10px 15px;
      text-align: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3764705882);
      font-family: 'ProximaNova-Regular';
      cursor: pointer;

      &:last-child {
        border: none;
      }

      &:hover {
        color: #ff6ce2;
        font-weight: bold;
      }
      .activeQuality {
        color: #ff6ce2;
      }
    }
  }
}

.video-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.video-container::before {
  content: '';
  display: block;
  padding-top: 56.25%;
}

.video-container video {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - 50px);
  height: 90%;
}

.fullScreen {
  // position: fixed;
  // top: 0px;

  // width: 100%;
  // height: auto;
  .player-icon:hover {
    path {
      // fill: #ff6ce2;
    }
    text {
      fill: #ff6ce2;
    }
  }

  video {
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100% - 50px);
    height: 100vh;
  }
}
.player-forward, .player-rewind {
  svg {
    filter: grayscale(1);
  }
  &:hover {
    svg {
      filter: grayscale(0);
    }
  }
}
@media only screen and (min-width: 1280px) {
  .landing-info-wrapper {
    margin-top: -16vh;
    position: relative;
    z-index: 1;
  }
  .home-banner-b {
    position: relative;
    z-index: 1;
  }
}
@media only screen and (min-width: 320px) and (max-width: 968px) {

  .player-volume input[type=range] {
    width: 60px;
  }
  .videoPlayerContainer {
    .static_page_container {
    
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      z-index: 99;
      width: 100%;
      left: 0px;
      .static_page_content {
        position: static;
        margin-bottom: 14px;
        font-size: 20px;
        padding: 0px 20px;
        .page_title {
          position: static;
          font-size: 20px;
          text-align: center;
        }
  
        .asset_title {
          position: static;
          padding-bottom: 17px;
          font-size: 35px;
          margin-bottom: 30px;
          text-align: center;
        }
  
        .present_txt {
          position: static;
        }
        .powerd-by {
          margin-bottom: 15px;
        }
  
        .logo {
          position: static;
        }
  
        .cta_btn {
          position: static;
          
        }
  
        .enjoy_txt {
          user-select: none;
          font-size: 20px !important;
          position: absolute;
          top: 347px;
          font-size: 38px;
          text-align: center;
          color: #999999;
          .letUs_txt {
            // cursor: pointer;
            color: white;
            text-decoration: none;
            // &:hover {
            //   color: #ff6ce2;
            // }
          }
        }
      }
    }
    .videoPlayerTop  {
      .playerContainer {
        left: 0px;
      }
    }
    .playerContainer {
      .video-container {
        video {
          height: 100%;
        }
      }
    }
    &.ChatON {
      .playerContainer {
        .video-container {
          &.fullScreen {
            video {
              width: 100%;
              padding-left: unset;
            }
          }
          video {
            width: 61%;
            padding-left: 7%;
          }
        }
      }
      .videoPlayerTop {
        
        .leftMenuContainer {
          padding-left: 22px;
          .menuLogo {
            margin-right: 40.2px;
            width: 20px;
          }
        }
        .rightMenuContainer {
          width: 40%;
          padding-bottom: 43vh;
          .rightMenuBottom {
            position: fixed;
            top: 0px;
            z-index: 2;
            right: 44px;
            top: 11px;
            ul {
              .player-icon {
                display: none;
              }
              .closeicon {
                display: block;
              }
            }
          }
        }
      }
      .live-chat-container {
        position: absolute !important;
        right: 0px !important;
        z-index: 1 !important;
        bottom: 140px;
        background: #000;
        width: 100%;
        height: 100%;
        top: 0px;
        border-left: 1px solid #606060;
        .live-inner {
          height: 98%;
          position: relative;
          &:after {
            display: none;
          }
        }
        .list-styles {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          flex-grow: 1;
          overflow: auto;
          padding: 10px;
          height: calc(100% - 115px);
          width: 100%;
          margin-top: 10px;
          .message-styles {
            padding: 8px 0px;
          }
        }
      }
    }
    .videoPlayerBottom {
      width: 75%;
      padding: 0 0px;
      position: absolute;
      margin: auto;
      padding-top: 0px;
      bottom: 0px;
      display: flex;
      flex-direction: column;
      background-color: transparent;
      left: 0px;
      right: 0px;

      .bottomMenuTop {
        .progressBarContainer {
          img {
            display: none;
          }
        }
      }
      .bottomMenuBottom {
        padding-bottom: 7px;
        padding-top: 5px;
        height: 60px;
        .bottomLeft {
          min-width: unset;
          
          li {
            &.player-duration {
              // font-size: 12px;
              // display: none;
            }
            &.player-volume {
              // display: none;
            }
            &.player-rewind {
              left: 30%;
            }
            &.player-playpause {
              left: 0px;
              right: 0px;
              margin: auto !important;
            }
            &.player-forward {
              right: 30%;
            }
            &.player-rewind,
            &.player-playpause,
            &.player-forward {
              // padding: 15px;
              // background-color: #000;
              position: fixed;
              top: 0;
              bottom: 0;
              margin: auto 0;
              height: 60px;
              width: 60px;
              // border-radius: 50px;
              display: flex;
              align-items: center;
              justify-self: center;
              // border: 1px solid #ffffff80;
              .player-icon {
                width: 50px;
                height: 50px;
                
              }
            }
          }
        }
        .bottomCenter {
          min-width: unset;
          li {
            span {
              // font-size: 12px;
            }
          }
        }
        .bottomRight {
          min-width: unset;
          position: fixed;
          right:-8px;
          top: 20px;
          flex-direction: column-reverse;
          width: 70px;
          li.quality-icon {
            position: fixed;
            bottom: 27%;  
          }
          .qualityContainer .qualityDropDownContainer {
            position: absolute;
            bottom: unset;
            top: -190px;
            right: 68px;
            background: rgb(81 81 81 / 90%);
            padding-right: 10px;
            padding-left: 10px;
            z-index: 1;
            border: none;
            border-radius: 5px;
            
            &:after {
              content: "";
              right: -30px;
              position: absolute;
              bottom:unset;
              top:200px;
              height: 2px;
              border-color: transparent transparent transparent rgb(81 81 81 / 90%);
              border-width: 10px 15px 10px 15px;
              border-style: solid;
              margin: 0px !important;
            }
        }
        }
      }
    }
  }
  .videoPlayerContainer .videoPlayerTop .playerContainer .SolomediaDetailOverlay .overlayLogo{
    position: absolute;
    left: 74vw;
    top: calc(100vh - 130px);
  }
  .videoPlayerContainer .videoPlayerTop .playerContainer .SolomediaDetailOverlay .videoTitle {
    margin-top: -15px;
  }
  .videoPlayerContainer .videoPlayerTop .playerContainer .SolomediaDetailOverlay .topLine {
    width: 100vw;
  }
  .mediaDetailOverlay.fullscreen {
    display: none;
  }
}
.active-chat {
  visibility: visible;
}
.in-active-chat {
  visibility: hidden;
}

@media only screen and (min-width: 320px) and (max-width: 470px) {
  .videoPlayerContainer .videoPlayerBottom .bottomMenuBottom .bottomRight .qualityContainer .qualityDropDownContainer {
    bottom: -101px;
    top: unset;
    right: 68px;
  }
  .videoPlayerContainer .videoPlayerBottom .bottomMenuBottom .bottomRight .qualityContainer .qualityDropDownContainer:after {
    top:100px;
  }
  .mediaDetailOverlay.fullscreen {
    display: none;
  }
  .videoPlayerContainer .videoPlayerTop .playerContainer .SolomediaDetailOverlay .overlayLogo{
    position: absolute;
    left: 74vw;
    top: 145px;
  }
  .videoPlayerContainer .videoPlayerTop .playerContainer .SolomediaDetailOverlay .videoTitle {
    margin-top: -15px;
  }
  .videoPlayerContainer .videoPlayerTop .leftMenuContainer {
    padding-left: 22px;
  }
  .videoPlayerContainer .videoPlayerBottom .bottomMenuBottom .bottomLeft li.player-rewind {
    left: 20%;
  }
  .videoPlayerContainer .videoPlayerBottom .bottomMenuBottom .bottomLeft li.player-forward {
    right: 20%;
  }
  .videoPlayerContainer .videoPlayerTop .playerContainer {
    position: relative;
    top: 26px;
    left: -41px;
  }
  .videoPlayerContainer .videoPlayerBottom .bottomMenuBottom .bottomCenter {
    display: none;
  }
  .videoPlayerContainer.ChatON .videoPlayerTop .rightMenuContainer .rightMenuBottom {
    position: absolute;
    right: 16px;
    top: 358px;
  }
  .videoPlayerContainer .playerContainer .video-container video {
    // height: unset;
    // margin-top: 65px;
    padding-right: 0px;
  }
  .videoPlayerContainer.ChatON .videoPlayerTop .rightMenuContainer {
    width: 100%;
  }
  .videoPlayerContainer.ChatON .playerContainer .video-container video{
    width: 100%;
    padding-left: 0%;
  }
  .videoPlayerContainer .videoPlayerTop .rightMenuContainer {
    background-color: transparent;
    padding-bottom: 0px;
    max-height: 230px;
    margin-right: 2px;
    width: 46px;
  }
  .videoPlayerContainer.ChatON .live-chat-container {
    height: calc(100vh - 350px);
    bottom: 0px;
    top:unset;
    position: fixed !important;
  }
  .videoPlayerContainer .videoPlayerBottom .bottomMenuBottom .bottomRight li.quality-icon {
    position: static;
    margin-top: 22px;
  }
  .videoPlayerContainer .videoPlayerBottom .bottomMenuBottom .bottomRight .player-icon{
    // margin-top: 1rem;
  }
  .videoPlayerContainer .videoPlayerBottom .bottomMenuBottom .bottomRight {
    width: 50px;
    top: 18px;
    right: 0px;
  }
  .videoPlayerContainer .videoPlayerBottom {
    top: 248px;
    width: 92%;
    bottom: unset;
  }
  .videoPlayerContainer .videoPlayerBottom .bottomMenuBottom .bottomLeft li.player-rewind, .videoPlayerContainer .videoPlayerBottom .bottomMenuBottom .bottomLeft li.player-playpause, .videoPlayerContainer .videoPlayerBottom .bottomMenuBottom .bottomLeft li.player-forward {
    bottom: unset;
    top: 120px;
  }
  .player-volume input[type=range] {
    top: -7px;
  }
  .videoPlayerContainer .playerContainer .video-container video {
    max-height:300px;
  }
  .videoPlayerContainer .videoPlayerTop .playerContainer .SolomediaDetailOverlay {
    top: 1px;
  }
}

.play-pause-player{
  position: fixed;
  margin: auto;
  z-index: 999;
  left: 0;
  right: 0;
  top: 50%;
  width: 150px;
}

@media only screen and (min-width: 450px) and (max-width: 968px) {
  .videoPlayerContainer .videoPlayerTop .rightMenuContainer {
    width: 31px;
    right: 10px;
    height: 84vh;
  }
}

@media only screen and (min-width: 320px) and (max-width: 968px) {
  .play-pause-player{
   display: none;
  }
}

.play-pause-player {
  padding: 15px;
  background-color: #000;
  left: 0;
  right: 0;
  bottom: 8vh;
  top: 0px;
  z-index: 999;
  margin: auto;
  height: 120px;
  width: 120px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-self: center;
  border: 1px solid rgba(255, 255, 255, 0.5019607843);
  .player-icon {
    cursor: pointer;
    width: 70px;
    height: 70px;
    margin: auto;

    &:hover {
      .st1 {
        fill: #ff6ce2;
      }

      text {
        fill: #ff6ce2;
        stroke: #ff6ce2;
      }

      g rect {
        fill: #ff6ce2;
      }
    }

  }
}

@media only screen and (min-width: 320px) and (max-width: 968px) {
  .play-pause-player {
    display: none;
  }
}

video::-webkit-media-controls {
  display: none !important;
}
