.facebook-btn,
.google-btn {
  button {
    text-align: center !important;
    padding: 10px 25px !important;
    color: #ffffff !important;
    background-image: linear-gradient(to top, #360d2a, #5c0e44) !important;
    border: 1px solid #707070 !important;
    font-size: 15px !important;
    margin-bottom: 20px !important;
    text-align: center !important;
    padding: 10px 25px !important;
    color: #ffffff !important;
    background-image: linear-gradient(to top, #360d2a, #5c0e44) !important;
    border: 1px solid #999999 !important;
    font-size: 15px !important;
    margin-bottom: 20px !important;
    &:hover{
      border: 1px solid #999999 !important;
      background: linear-gradient(to bottom,
        rgba(255, 108, 226, 1),
        rgba(194, 66, 168, 0.78)) !important;
    }
  }
}

.google-btn,
.facebook-btn {
  button {
    div {
      div {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;
        margin-left: -5px;
      }
    }
  }

  img {
    width: 24px !important;
    height: 24px;
    padding-right: 5px;
  }
}

.facebook-btn button,
.google-btn button {
  padding: 14px !important;
}
.border-left {
  border-left: 1px solid #999999;
  height: 50px !important;
  line-height: 50px;
  padding-left: 10px;
}

.google-button-wrapper {
  text-align: center !important;
  color: #ffffff !important;
  background-image: linear-gradient(to top, #360d2a, #5c0e44) !important;
  border: 1px solid #707070 !important;
  font-size: 15px !important;
  margin-bottom: 20px !important;
  text-align: center !important;
  color: #ffffff !important;
  background-image: linear-gradient(to top, #360d2a, #5c0e44) !important;
  border: 1px solid #999999 !important;
  font-size: 15px !important;
  margin-bottom: 20px !important;
  height: 50px;
  width: 260px;
  margin-left: 5px;
  border-radius: 3px;
  display: flex;
  align-items: center;

  .border-left {
    border-left: 1px solid #999999;
    height: 50px !important;
    line-height: 50px;
    padding-left: 10px;
  }
  .icon-panel{
    width: 47px;
  }
  img {
    width: 24px !important;
    height: 24px;
    padding-right: 5px;
  }

  &:hover {
    border: 1px solid #999999 !important;
    background: linear-gradient(to bottom,
        rgba(255, 108, 226, 1),
        rgba(194, 66, 168, 0.78)) !important;
  }
}
