.loader-bg {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
  text-align: center;
  z-index: 111;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  display: flex;
  align-items: center;

  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 150px;
    margin: 0 auto;
    padding-top: 15rem;
    padding-bottom: 20rem;
    img {
      width: 100%;
    }
  }
}

.event-container-wrapper {
  background-color: transparent;
}

.page-container {
  // height: 100vh;
}
