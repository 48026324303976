.comman-container {
  width: 100%;
  height: 58rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(261deg, rgba(0, 0, 0), rgba(0, 0, 0));
  position: relative;
  min-height: calc(100vh - 110px);
  .comman-bg-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }
  .logo-wrapper {
    width: 198px;
    height: 79px;
    margin: 0 auto;
    position: absolute;
    top: 6%;
    left: 0;
    right: 0;
    z-index: 1;
    cursor: pointer;
    .logo-img-container {
      width: 100%;
      object-fit: none;
    }
  }
  .login-line-wrapper{
    background-color: black;
    width: 100%;
    height: 17px;
    border-top: 1px solid #999999;
    position: absolute;
    bottom: 0;
  }
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .comman-container .comman-bg-img  {
    visibility: hidden;
  }
  .comman-container .logo-wrapper {
    height: 79px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 2%;
    width: 198px;
    z-index: 1;
}
.comman-container .login-line-wrapper {
 display: none;
}
.comman-container {
  width: 100%;
  height: 43rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(261deg, rgb(0, 0, 0), rgb(0, 0, 0));
  position: relative;
  min-height: calc(100vh - 110px);
}
}