@import url('../src/style.scss');
@import url('../src/components/header/header.scss');
@import url('../src/screen/sign-in/sign-in.scss');
@import url('../src/components/back-ground-page-wrapper/back-ground-page-wrapper.scss');
@import url('../src/components/back-ground-page-wrapper/comman.scss');
@import url('../src/components/footer/fotter.scss');
@import url('../src/components/header/header.scss');
@import url('../src/components/carousel/carousel.scss');
@import url('../src/components/never-miss-out/never-miss-out.scss');
@import url('../src/screen/experience/experience-the-demo.scss');
@import url('../src/components/faq/faq.scss');
@import url('../src/components/cast-card/cast-card.scss');
@import url('../src/components/event-details/event-details.scss');

body {
  margin: 0;
  padding: 0;
}
::-webkit-scrollbar {
  display: none;
}
input[type='range'] {
  accent-color: #fe6ce2;
}

@font-face {
  font-family: 'ProximaNova-Regular';
  src: url(./styles/fonts/ProximaNova-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'ProximaNova-SemiBold';
  src: url(./styles/fonts/ProximaNova-Semibold.ttf) format('truetype');
}
@font-face {
  font-family: 'ProximaNova-Bold';
  src: url(./styles/fonts/ProximaNova-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'ProximaNova-Extra-Condensed-Bold';
  src: url(./styles/fonts/ProximaNovaExCn-Extrabld.ttf) format('truetype');
}
@font-face {
  font-family: 'ProximaNova-Extra-Condensed-Light';
  src: url(./styles/fonts/ProximaNovaExCn-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'ProximaNova-Extra-Condensed-Regular';
  src: url(./styles/fonts/ProximaNovaExCn-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'fontsFree-Net-Proxima-Nova-Extra-condesed-Medium';
  src: url(./styles/fonts/FontsFree-Net-Proxima-Nova-ExCn-Reg.otf)
    format('truetype');
}
@font-face {
  font-family: 'Proxima-Nova-Medium';
  src: url(./styles/fonts/proxima-nova-medium.ttf) format('truetype');
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
}
@media only screen and (min-width: 1024px) and (max-width: 1900px) {
}
.App {
  overflow-x: hidden; /* This will hide horizontal overflow */
}
