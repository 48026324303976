.upcoming-wrapper {
  background-color: black;
  width: 100%;
  display: flex;
  flex-direction: column;

  .upcoming-title {
    font-family: "ProximaNova-SemiBold";
    font-size: 18px;
    letter-spacing: 1.44px;
    color: #999999;
    text-transform: uppercase;
    padding-top: 50px;
    margin-left: 117px;
    line-height: 30px;
  }

  .card-Potrate {
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 31px;
    margin-left: 117px;
    margin-bottom: 50px;
  }

  .card {
    margin-bottom: 15px;
    cursor: pointer;

    .main-card {
      width: 272px;
      height: 343px;
      display: flex;
      flex-direction: column;
      margin-top: 35px;
      position: relative;

      .card-box {
        width: 254px;
        height: 273px;
        float: right;
        margin-left: 1rem;
        position: relative;

        img {
          width: 100%;
          object-fit: cover;
        }

        .card-text {
          position: absolute;
          color: #ffffff;
          font-family: "fontsFree-Net-Proxima-Nova-Extra-condesed-Medium";
          line-height: 40px;
          font-size: 50px;
          text-align: left;
          text-transform: uppercase;
          bottom: 10px;
          left: 16px;
          width: 175px;
        }
      }

      .v-logo {
        position: absolute;

        img {
          width: 100%;
        }
      }

      .card-info-title {
        font-family: "Proxima-Nova-Medium";
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 1.4px;
        color: #999999;
        text-transform: uppercase;
        margin-top: 22.5px;
        margin-left: 1rem;
      }

      .card-details-wrapper {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-left: 1rem;
        margin-top: 5px;

        .tag {
          font-family: "Proxima-Nova-Medium";
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 1.28px;
          color: #ffffff;
          text-transform: uppercase;
          border: 1px solid #707070;
          padding: 3px 15px;
          background-color: #262626;
        }

        .live-tag {
          font-family: "Proxima-Nova-Medium";
          font-size: 16px;
          letter-spacing: 1.28px;
          color: #ffffff;
          border: 1px solid #707070;
          border: 1px solid #ffffff;
          text-transform: uppercase;
          background-image: linear-gradient(to top, #360d2a, #5c0e44);
          width: 70px;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          height: 26.8px;
          span {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background-color: #ffffff;
          }
        }

        .card-info {
          font-family: "Proxima-Nova-Medium";
          font-size: 14px;
          letter-spacing: 1.28px;
          line-height: 20px;
          color: #c242a8;
          text-transform: uppercase;
        }
      }
    }
  }
}
hr {
  width: 100%;
  height: 10px;
  border: none;
  border-bottom: 1px solid #c242a8;
}

.content-wrapper {
  width: 100%;
  height: 300px;
  border-top: 1px solid #c242a8;
  margin-bottom: 2rem;
  background-color: black;

  .content-title {
    font-family: "ProximaNova-SemiBold";
    font-size: 50px;
    line-height: 50px;
    color: #ffffff;
    margin-top: 31px;
    margin-bottom: 145px;
    background-color: black;
    margin-left: 117px;
  }
}

.card-box:hover .div {
  color: #c242a8 !important;
}

.main-card:hover .card-box img {
  opacity: 0.5;
  // border: 2px solid #c242a8;
}
.main-card:hover .card-text {
  color: #c242a8 !important;
}
.main-card:hover .v-logo g path {
  fill: #c242a8 !important;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .upcoming-wrapper .card {
    margin-left: 1rem;
    margin-bottom: 1rem;

    // .upcoming-title {
    //   font-size: 15px;
    //   padding-top: 120px !important;
    //   margin-left: 1rem;
    // }
  }
  .upcoming-wrapper .upcoming-title {
    margin-left: 30px !important;
  }

  .content-wrapper .content-title {
    font-size: 35px;
    line-height: 40px;
    color: #ffffff;
    font-family: "ProximaNova-SemiBold";
    background-color: black;
    text-align: left;
    margin: 30px 89px 77.5px 30px;
  }
  .upcoming-wrapper .card-Potrate {
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 31px;
    margin-left: 15px;
    margin-bottom: 70.47px;
  }
  .upcoming-wrapper .card .main-card {
    width: 181px;
    height: 259px;
    display: flex;
    flex-direction: column;
    margin-top: 35px;
    position: relative;
    // border: 2px solid red;
  }
  .upcoming-wrapper .card .main-card .card-box {
    width: 165.07px;
    height: 177.41px;
    float: right;
    margin-left: 1rem;
    position: relative;
  }
  .upcoming-wrapper .card .main-card .card-box .card-text {
    position: absolute;
    color: #ffffff;
    font-family: "fontsFree-Net-Proxima-Nova-Extra-condesed-Medium";
    line-height: 32px;
    // font-size: 32px;
    font-size: 30px;
    text-transform: uppercase;
    bottom: 0px;
    left: 5px;
  }
  .upcoming-wrapper .card .main-card .card-info-title {
    font-size: 10px;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    color: #999999;
    font-family: "ProximaNova-Regular";
    margin-top: 12.25px;
    margin-left: 20.32px;
  }
  .upcoming-wrapper .card .main-card .card-details-wrapper .tag {
    padding: 3px 15px;
    color: #ffffff;
    font-size: 10px;
    font-family: "ProximaNova-Regular";
    text-transform: uppercase;
    letter-spacing: 1.28px;
    border: 1px solid white;
  }
  .upcoming-wrapper .card .main-card .card-details-wrapper .card-info {
    font-size: 16px;
    font-family: "Proxima-Nova-Medium";
    text-transform: uppercase;
    letter-spacing: 1.28px;
    line-height: 20px;
    color: #c242a8;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .content-wrapper .content-title {
    font-size: 40px;
    line-height: 40px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1900px) {
  .upcoming-wrapper .card .main-card {
    width: 220px;
    height: 250px;
  }
  .upcoming-wrapper .card .main-card .card-box {
    width: 200px;
    height: 215px;
  }
  .upcoming-wrapper .card .main-card .card-box .card-text {
    line-height: 33px;
    font-size: 40px;
    width: 150px;
  }
  .upcoming-wrapper .card .main-card .card-info-title {
    font-family: "Proxima-Nova-Medium";
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 1.4px;
    color: #999999;
    text-transform: uppercase;
    margin-top: 14.5px;
    margin-left: 1rem;
  }
  .upcoming-wrapper .card .main-card .card-details-wrapper .tag {
    padding: 2px 10px;
  }
  .content-wrapper .content-title {
    font-size: 40px;
    line-height: 40px;
  }
  .content-wrapper {
    width: 100%;
    height: 200px;
}
}
