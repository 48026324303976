.support-footer {
  a {
    font-family: "Proxima-Nova-Medium";
    font-size: 12px;
    letter-spacing: 1.2px;
    line-height: 14px;
    color: #B3B3B3;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      color: #c242a8;
    }
  }
}
.site-footer {
  border-top: 1px solid #707070;
}
.sub-footer {
  background-color: black;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .sub-footer-first-section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 19rem;
    margin-left: 117px;
    cursor: pointer;
    div a {
      font-family: "Proxima-Nova-Medium";
      font-size: 12px;
      letter-spacing: 1.2px;
      line-height: 14px;
      color: #B3B3B3;
      text-transform: uppercase;
      cursor: pointer;
      &:hover {
        color: #c242a8;
      }
    }
  }
  .sub-footer-last {
    font-family: "Proxima-Nova-Medium";
    font-size: 12px;
    letter-spacing: 1.2px;
    line-height: 14px;
    color: #B3B3B3;
    text-transform: uppercase;
    opacity: 100%;
    margin-right: 117px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  
  .main-container {
    width: 19rem;
    height: 42rem;
    margin: 0 auto;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    position: absolute;
    top: 15%;
    left: 2%;
    z-index: 1;
    .container-heading {
      color: #ffffff;
      text-align: center;
      font-weight: bold;
      font-size: 20px;
      width: 272px;
      height: 37px;
    }
    .container-description {
      color: grey;
      font-size: 16px;
      text-align: center;
      width: 275px;
      height: 65px;
    }
  }
  .devide-lines {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 255px;
    height: 24px;
  }
  
  .sub-description {
    font-size: 16px;
    color: gray;
    text-align: center;
    width: 232px;
    height: 65px;
    margin: 2rem 0;
  }
  .hover-text {
    color: gray;
    width: 295px;
    height: 23px;
    text-align: center;
  }
  .sub-footer {
    background-color: black;
    height: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    .footer-first-section {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      gap: 1rem;
    }
  }
  .sub-footer {
    background-color: black;
    height: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    gap: 1.5rem;
    text-align: center;
  }
  .sub-footer .sub-footer-first-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    margin-left: 10px;
  }
  .sub-footer .sub-footer-last {
    margin-right: 0px;
    opacity: 100%;
    padding-bottom: 2rem;
}
.site-footer{
  padding: 0px 20px;
}
}
@media only screen and (min-width: 768px) and (max-width: 1324px) {
  .sub-footer {
    background-color: black;
    height: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    gap: 1.5rem;  
    text-align: center;
  }
  .sub-footer .sub-footer-first-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5rem;
    cursor: pointer;
    margin-left: 0px;
  }
  .sub-footer .sub-footer-last {
    margin-right: 0px;
    opacity: 100%;
    padding-bottom: 1rem;
}
}
@media only screen and (min-width: 1024px) and (max-width: 1900px) {
  .sub-footer {
    height: 80px;
  }
  .sub-footer .sub-footer-first-section {
    gap: 12rem;
  }
}
