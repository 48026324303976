.sign-section-wrapper {
  background-color: #101010;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  padding: 2rem 0;
  border-bottom: 1px solid #d0d0d0;
  border-top: 1px solid #d0d0d0;
  .sign-section-heading {
    text-align: center;
    color: #ffffff;
    $default-font-bold: "ProximaNova Bold";
    font-size: 10px;
    text-transform: uppercase;
  }
  .email-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    .email-box {
      width: 20rem;
      height: 3rem;
      border: none;
      outline: none;
      background-color: #101010;
      color: #636363;
      border-bottom: 1px solid #636363;
      cursor: pointer;
    }
    .email-wrapper-btn-sign {
      width: 120px;
      height: 50px;
      background-color: #303030;
      color: #ffffff;
      text-transform: uppercase;
      $default-font-bold: "ProximaNova Bold";
      border: 1px solid #d0d0d0;
      cursor: pointer;
      &:hover {
        border: 1px solid white;
        background-color: #f567d9;
      }
    }
  }
}
