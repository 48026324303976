.order-confirmation-wrapper{
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 100%;
    gap: 4rem;
    margin: 10rem 0rem;
    h1.confirm-title{
        font-size: 30px;
        line-height: 22px;
        letter-spacing: 0.6px;
        font-family: "ProximaNova-SemiBold";
        text-align: center;
        color: #c242a8;
    }
    .thank-you-desc{
        font-family: "Proxima-Nova-Medium";
        color: white;
        font-size: 30px;
        line-height: 40px;
    }
    .thank-you-logo{
    }
    .thank-you-see-you-soon-text{
        font-family: "ProximaNova-SemiBold";
        font-size: 30px;
        line-height: 40px;
        letter-spacing: 0.6px;
        text-align: center;
        color: white;
    }
}