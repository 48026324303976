.live-chat-container {
  position: fixed !important;
    right: 82px !important;
    z-index: 1 !important;
    bottom: 140px;
    // background: #4b4b4b;
    background: rgb(0 0 0 / 73%);
    width: 378px;
    height: 463px;
    .live-inner {
      height: 100%;
      position: relative;
      &:after {
        content: "";
        right: -30px;
        position: absolute;
        bottom: 35px;
        height: 2px;
        border-color: transparent transparent transparent rgb(0 0 0 / 73%);
        border-width: 10px 15px 10px 15px;
        border-style: solid;
      }
    }
}

.header-styles {
  color: #ffffff;
  font-size: 1.4rem;
  padding: 10px 15px;
  text-align: left;
  border-bottom: 1px solid #ffffff60;
  font-family: "ProximaNova-Regular";
}

.list-styles {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: scroll;
  // padding: 10px;
  max-height: 350px;
  width: 100%;
  // margin-top: 10px;
  * {
    box-sizing: border-box;
  }
}

.message-styles {
  background-color: transparent;
  border-radius: 5px;
  color: #ffffff;
  font-size: 1rem;
  margin: 5px;
  padding: 8px 15px;
  font-family: "Proxima-Nova-Medium";
  .profile-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 15px;
    width: 20px;
    height: 20px;
  }
}

.footer-styles {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.input-styles {
  flex-grow: 1;
  font-size: 1.1rem;
  padding: 10px 30px;
  background-color: transparent;
  border: 0px;
  outline: none;
  border-top: 1px solid rgba(255, 255, 255, 0.3764705882);
  color: #ffffff;
  font-family: "Proxima-Nova-Medium";
}

.message-styles {
  background-color: transparent;
  border-radius: 5px;
  color: #ffffff;
  font-size: 1.1rem;
  margin: 2px 0;
  padding: 8px 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // margin-right: 33px;
  // gap: 20px;
  width: 100%;
  .chat-userimg {
    background-color: #797979;
    border-radius: 50px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    overflow: hidden;
    margin-right: 8px;
    span {
      width: 21px;
      letter-spacing: 20px;
      margin-left: 9px;
      // margin-top: 3px;
      overflow: hidden;
      font-weight: 600;
    }
  }
  .chat-replies {
    width: calc(100% - 78px);
    font-size: 0.8rem;
    text-align: left;
    .chat-username {
      color: #a3a3a3;
      margin-right: 7px;
      font-weight: 600;
    }
    .chat-message {
      color: #f1f1f1;
    }
  }
}

.footer-styles {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.input-styles {
  flex-grow: 1;
  font-size: 1.1rem;
  padding: 10px 15px;
  background-color: transparent;
  border: 0px;
  outline: none;
  border-top: 1px solid rgba(255, 255, 255, 0.3764705882);
  color: #fff;
}

.profile-wrapper {
  background: linear-gradient(
    to bottom,
    rgba(102, 14, 75, 1),
    rgba(69, 16, 54, 0.78)
  );
  color: white;
  padding: 5px;
  height: 20px;
  width: 26px;
  border: 1px solid #999999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  border-radius: 4px;
}

.list-styles::-webkit-scrollbar {
  width: 6px;
  cursor: pointer;
}
.list-styles::-webkit-scrollbar-track {
  background: #888;
  border-radius: 4px;
}

.list-styles::-webkit-scrollbar-thumb {
  background: #f1f1f1;
  border-radius: 4px;
}
.list-styles::-webkit-scrollbar-thumb:hover {
  background: #555;
}
