.contentrail-wrapper {
  width: 100%;
  background-color: black;

  .rail-title {
    font-size: 20px;
    color: #ffffff;
    letter-spacing: 1.6px;
    font-family: "ProximaNova-SemiBold";
    text-transform: uppercase;
  }
  .circle-card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .Circle-card {
      width: 208px;
      height: 208px;
      img {
        width: 100%;
      }
    }
    .Circle-card-title {
      font-size: 16px;
      text-align: center;
      letter-spacing: 1.6px;
      font-family: "Proxima-Nova-Medium";
      line-height: 17px;
      margin-top: 26px;
      color: #999999;
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .contentrail-wrapper .circle-card-wrapper .Circle-card {
    width: 150px;
    height: 150px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1900px) {
  .event-container-wrapper .casting-wrapper .contentrail-wrapper .swiper-slide {
    width: 210px !important;
  }
  .contentrail-wrapper .circle-card-wrapper .Circle-card {
    width: 150px;
    height: 150px;
  }
  .contentrail-wrapper .circle-card-wrapper .Circle-card-title {
    font-size: 14px;
    letter-spacing: 1.6px;
    line-height: 10px;
  }
  .event-container-wrapper .casting-wrapper .contentrail-wrapper .swiper-slide {
    width: 187px !important;
  }
}
