.nevigation-wrapper {
  background-color: black;
  height: 90px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #707070;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;

  .hamburger {
    display: none;
  }

  .Logo-wrapper {
    width: 180px;
    height: 80px;
    margin-left: 117px;
    img {
      width: 100%;
    }
  }
  .menu-footer-last {
    display: none;
  }
  .menu-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 65px;
    margin-right: 117px;

    a {
      font-family: "Proxima-Nova-Medium";
      font-size: 14px;
      letter-spacing: 1.12px;
      line-height: 17px;
      color: #ffffff;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        color: #c242a8;
      }
    }

    .active {
      color: #c242a8;
    }
  }

  .burger-menu {
    display: none;
    flex-direction: column;
    cursor: pointer;

    span {
      height: 2px;
      width: 25px;
      background-color: #ffffff;
      margin: 3px;
    }
  }
}

.static-menu {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 65px;

  .sign-out-wrapper {
    display: none;
  }
}

.btn-signin {
  font-family: "Proxima-Nova-Medium";
  font-size: 18px;
  text-align: center;
  padding: 10px 25px;
  color: #ffffff;
  background: linear-gradient(
    to bottom,
    rgba(102, 14, 75, 1),
    rgba(69, 16, 54, 0.78)
  );
  border: 1px solid #999999;
}

.btn-signin:hover {
  color: #ffffff !important;
  border: 1px solid #999999;
  background: linear-gradient(
    to bottom,
    rgba(255, 108, 226, 1),
    rgba(194, 66, 168, 0.78)
  );
}

.sign-new-wrapper {
  width: 140px;
  height: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  background-color: #000000;
  position: absolute;
  top: 100%;
  right: 4%;
  z-index: 1;

  .cart-wrapper {
    width: 20px;
    height: 20px;
    float: right;
    float: right;
    align-self: flex-end;
    margin-right: 2rem;

    .card-box {
      width: 20px;
      height: 20px;
    }
  }

  .sing-btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-left: 15px;
    margin: 10px 0px;
    cursor: pointer;

    .sing-icon {
      margin-top: 3px;
      img {
        width: 100%;
        transform: rotate(180deg);
      }
    }

    .sing-out {
      color: #ffffff;
      font-size: 15px;
      font-style: "ProximaNova-Regular";
    }
  }
}

@media only screen and (min-width: 1280px) {
  .home-banner {
    font-size: 1.4rem;
  }
  .heroContainer .swiper-img {
    object-position: center -16vh;
  }
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .menu-wrapper {
    display: none !important;
  }

  .hamburger {
    display: block !important;
    cursor: pointer;
    margin-left: 2rem;
    margin-top: 1rem;
    height: 3rem;
    position: absolute;
  }

  .menu-wrapper {
    position: relative;

    .menu-items {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      background: black;
      width: 100%;
      flex-direction: column;
      gap: 1rem;
      padding: 1rem;
    }

    .burger-menu.open + .menu-items {
      display: flex;
      border: 1px solid gray;
    }
  }

  .menu-mobile {
    margin-top: 90px;
    display: block;
    width: 100%;
    display: flex;
    z-index: 999;
    background: black;
    position: absolute;
    top: 0px;
    width: 100% !important;
    height: calc(100vh - 90px) !important;
    z-index: 999;
    left: 0;
    .menu-footer-last {
      border-top: 1px solid #999999;
      display: block;
      font-family: "Proxima-Nova-Medium";
      font-size: 12px;
      letter-spacing: 1.2px;
      line-height: 14px;
      color: #b3b3b3;
      cursor: pointer;
      text-transform: uppercase;
      width: 100%;
      position: absolute;
      bottom: 0px;
      padding-top: 40px;
      padding-bottom: 40px;
      box-sizing: border-box;
      text-align: center;
    }

    .static-menu {
      display: inline-flex;
      gap: 0rem;
      flex-direction: column;
      gap: 2rem;
      width: 100%;
      margin-top: 250px;
      a {
        width: 100%;
        color: #ffffff;
        font-size: 20px !important;
        line-height: 14px;
        text-align: left;
        text-decoration: none;
        border-bottom: 1px solid #c242a8;
        padding-bottom: 30px;
        padding-left: 70px;
        line-height: 1;
        letter-spacing: 1.6px;
        box-sizing: border-box;
      }

      .sign-out-wrapper {
        display: block;
        align-self: start;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        margin-left: 60px;
        margin-bottom: 100px;
        margin-top: auto;
        padding-bottom: 600px;

        .sign-title {
          color: #ffffff;
          text-transform: uppercase;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: 1.6px;
          padding: 10px;
          font-style: "ProximaNova-Regular";
          border: 1px solid #999999;
          background: linear-gradient(
            to bottom,
            rgba(102, 14, 75, 1),
            rgba(69, 16, 54, 0.78)
          );
          &:hover{
            background: linear-gradient(
              to bottom,
              rgba(255, 108, 226, 1),
              rgba(194, 66, 168, 0.78)
            );
          }
        }
        .sign-title-out{
          color: #ffffff;
          text-transform: uppercase;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: 1.6px;
          padding: 10px;
          font-style: "ProximaNova-Regular";
          cursor: pointer;
        }
        .sign-out-icon {
          img {
            width: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }

  .menu-wrapper {
    position: relative;
    .menu-items {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      background: black;
      width: 100%;
      flex-direction: column;
      gap: 1rem;
      padding: 1rem;
    }

    .burger-menu.open + .menu-items {
      display: block;
    }
  }

  .btn-signin {
    display: none;
  }

  .nevigation-wrapper .Logo-wrapper {
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .static-menu {
    display: none;
  }
}

.profile-wrapper {
  background: linear-gradient(
    to bottom,
    rgba(102, 14, 75, 1),
    rgba(69, 16, 54, 0.78)
  );
  color: white;
  height: 35px;
  width: 38px;
  border: 1px solid #999999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  border-radius: 0px !important;
  &:hover {
    border: 1px solid #707070;
    background-image: linear-gradient(to top, #e25cc8, #a3398e);
  }
}
