.btn-event-buy-ref {
  button.StripeCheckout {
    width: 220px !important;
    height: 60px !important;
    color: white !important;
    font-size: 18px !important;
    font-family: "Proxima-Nova-Medium";
    background: linear-gradient(
      to bottom,
      rgba(102, 14, 75, 1),
      rgba(69, 16, 54, 0.78)
    ) !important;
    text-transform: uppercase !important;
    border: 1px solid #999999 !important;
    border-radius: 0px !important ;
    cursor: pointer !important;

    &:hover {
      background: linear-gradient(
        to bottom,
        rgba(255, 108, 226, 1),
        rgba(194, 66, 168, 0.78)
      ) !important;
    }
    span {
      background-image: none !important;
      box-shadow: none !important;
      border-radius: 0px !important;
      font-family: inherit !important;
      font-weight: inherit !important;
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .btn-event-buy-ref button.StripeCheckout {
    width: 200px !important;
    height: 50px !important;
  }
  button.StripeCheckout {
    width: 200px !important;
    height: 50px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .btn-event-buy-ref button.StripeCheckout {
    width: 200px !important;
    height: 50px !important;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1900px) {
  button.StripeCheckout {
    width: 200px !important;
    height: 50px !important;
  }
  .btn-event-buy-ref button.StripeCheckout {
    width: 200px !important;
    height: 50px !important;
  }
}
