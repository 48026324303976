.Main-experience-conatiner {
  width: 100%;
  background-color: black;
  display: flex;
  flex-direction: column;
  .solo-view-details {
    border-bottom: 1px solid #fe6ce2;
    background-position: center;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 90px;
      left: 0px;
      bottom: 6rem;
      background: rgb(0,0,0);
      background: -moz-linear-gradient(180deg, rgba(0,0,0,0.15) 20%, rgba(0,0,0,1) 20%, rgba(0,0,0,0.35) 20%, rgba(0,0,0,0.35) 40%, rgba(0,0,0,0.6) 40%, rgba(0,0,0,0.65) 60%, rgba(0,0,0,0.85) 60%, rgba(0,0,0,0.85) 80%, rgba(0,0,0,1) 80%, rgba(0,0,0,1) 100%);
      background: -webkit-linear-gradient(180deg, rgba(0,0,0,0.15) 20%, rgba(0,0,0,1) 20%, rgba(0,0,0,0.35) 20%, rgba(0,0,0,0.35) 40%, rgba(0,0,0,0.6) 40%, rgba(0,0,0,0.65) 60%, rgba(0,0,0,0.85) 60%, rgba(0,0,0,0.85) 80%, rgba(0,0,0,1) 80%, rgba(0,0,0,1) 100%);
      background: linear-gradient(180deg, rgba(0,0,0,0.15) 20%, rgba(0,0,0,1) 20%, rgba(0,0,0,0.35) 20%, rgba(0,0,0,0.35) 40%, rgba(0,0,0,0.6) 40%, rgba(0,0,0,0.65) 60%, rgba(0,0,0,0.85) 60%, rgba(0,0,0,0.85) 80%, rgba(0,0,0,1) 80%, rgba(0,0,0,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
    }
    .solo-view-bg {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // &:after {
      //   background: rgb(0,0,0);
      //   background: -moz-linear-gradient(180deg, rgba(0,0,0,0.15) 20%, rgba(0,0,0,1) 20%, rgba(0,0,0,0.35) 20%, rgba(0,0,0,0.35) 40%, rgba(0,0,0,0.6) 40%, rgba(0,0,0,0.65) 60%, rgba(0,0,0,0.85) 60%, rgba(0,0,0,0.85) 80%, rgba(0,0,0,1) 80%, rgba(0,0,0,1) 100%);
      //   background: -webkit-linear-gradient(180deg, rgba(0,0,0,0.15) 20%, rgba(0,0,0,1) 20%, rgba(0,0,0,0.35) 20%, rgba(0,0,0,0.35) 40%, rgba(0,0,0,0.6) 40%, rgba(0,0,0,0.65) 60%, rgba(0,0,0,0.85) 60%, rgba(0,0,0,0.85) 80%, rgba(0,0,0,1) 80%, rgba(0,0,0,1) 100%);
      //   background: linear-gradient(180deg, rgba(0,0,0,0.15) 20%, rgba(0,0,0,1) 20%, rgba(0,0,0,0.35) 20%, rgba(0,0,0,0.35) 40%, rgba(0,0,0,0.6) 40%, rgba(0,0,0,0.65) 60%, rgba(0,0,0,0.85) 60%, rgba(0,0,0,0.85) 80%, rgba(0,0,0,1) 80%, rgba(0,0,0,1) 100%);
      //   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
      //   content: "";
      //   position: absolute;
      //   left: 0px;
      //   bottom: 0px;
      //   width: 100%;
      //   height: 200px;
      //   z-index: 1;
      // }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative;
        visibility: hidden;
      }
    }
    .features-wrapper {
      margin: 0 auto;
      color: #ffffff;
      font-size: 50px;
      line-height: 78px;
      letter-spacing: 4px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "ProximaNova-SemiBold";
      padding-bottom: 2rem;
      z-index: 2;
      position: relative;
      padding-top: 1rem;
      background-color: #000;
      height: 6rem;
      box-sizing: border-box;
    }

    .player-info-wrapper {
      width: 90%;
      height: 100%;
      position: absolute;
      top: 100px;
        bottom: 0;
        left: 0%;
        right: 0;
        margin: 0 auto;
        display: flex;
        align-items: flex-start;
      .player-info {
        width: 100%;
        object-fit: contain;
      }
    }
  }
  .conatiner-feature-wrapper {
    width: 100%;
    height: auto;
    background-color: #000000;
    padding-top: 30px;
    padding-bottom: 30px;

    .feature-wrapper-inner {
      display: flex;
      flex-direction: row;
      gap: 2rem;
      justify-content: center;
      margin: 0 auto;
      width: 100%;
      .feature-description-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 43%;
        .feature-heading {
          display: flex;
          align-items: center;
          gap: 1rem;
          .feature-title-heading {
            font-family: "ProximaNova-SemiBold";
            font-size: 40px;
            line-height: 50px;
            color: #ffffff;
            text-align: left;
          }
          span {
            width: 60px;
            height: 60px;
            background-color: #000000;
          }
        }
        .feature-details {
          width: 600px;
          font-family: "Proxima-Nova-Medium";
          font-size: 20px;
          line-height: 30px;
          color: #ffffff;
        }
      }
      .feature-details-box {
        width: 43rem;
        height: 22rem;
        background-color: #3e3e3e;
        img {
          width: 100%;
          height: inherit;
        }
      }
    }
  }
  .line {
    border-bottom: 1px solid #fe6ce2;
  }
  .conatiner-feature-wrapper-two {
    width: 100%;
    height: auto;
    background-color: #000000;
    padding-top: 15px;
    padding-bottom: 15px;

    .feature-wrapper-inner-two {
      display: flex;
      flex-direction: row;
      gap: 11rem;
      justify-content: center;
      margin: 0 auto;
      width: 100%;
      .feature-description-wrapper-two {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 35%;
        .feature-heading-two {
          display: flex;
          align-items: center;
          gap: 1rem;
          .title-feature {
            font-size: 40px;
            line-height: 50px;
            color: #ffffff;
            text-align: left;
            font-family: "ProximaNova-SemiBold";
          }
          span {
            width: 60px;
            height: 60px;
            background-color: #000000;
          }
        }
        .feature-details-two {
          width: 600px;
          font-family: "Proxima-Nova-Medium";
          font-size: 20px;
          line-height: 30px;
          color: #ffffff;
        }
      }
      .feature-details-box-two {
        width: 43rem;
        height: 22rem;
        background-color: #3e3e3e;
        img {
          width: 100%;
          height: inherit;
        }
      }
    }
  }
  .upcoming-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 66px;
    .upcoming-heading {
      font-family: "ProximaNova-SemiBold";
      font-size: 40px;
      line-height: 50px;
      color: #d0d0d0;
      text-align: center;
    }
    .shows-btn {
      width: 250px;
      height: 60px;
      font-family: "Proxima-Nova-Medium";
      margin-bottom: 66px;
      font-size: 18px;
      border: 1px solid #707070;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff;
      background: linear-gradient(
        to bottom,
        rgba(102, 14, 75, 1),
        rgba(69, 16, 54, 0.78)
      );

      &:hover {
        cursor: pointer;
        border: 1px solid #707070;
        background: linear-gradient(
          to bottom,
          rgba(255, 108, 226, 1),
          rgba(194, 66, 168, 0.78)
        );
      }
    }
  }
}
.line-panel {
  height: 17px;
  border-top: 1px solid #fe6ce2;
  border-bottom: 1px solid #fe6ce2;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .Main-experience-conatiner {
    * {
      box-sizing: border-box;
    }
    .conatiner-feature-wrapper .feature-wrapper-inner .feature-details-box img, 
    .conatiner-feature-wrapper-two .feature-wrapper-inner-two .feature-details-box-two img {
      height: unset;
    }
  }
  .Main-experience-conatiner .conatiner-feature-wrapper .feature-wrapper-inner {
    display: flex;
    gap: 2rem;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    flex-direction: column-reverse;
  }
  .feature-description-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 1rem !important;
    padding-right: 2rem !important;
    width: 100% !important;
  }
  .feature-heading, .feature-title-heading {
    font-size: 30px !important;
    color: #ffffff;
    text-align: left;
    font-family: "ProximaNova-SemiBold";
    line-height: 1.1 !important;
  }

  .feature-details {
    width: 100% !important;
    font-size: 20px;
    font-family: "ProximaNova-Regular";
    color: #ffffff;
    padding: 20px 0px;
    text-align: left;
  }
  .feature-details-box {
    width: 100% !important;
    height: 14rem !important;
    background-color: #3e3e3e;
  }
  .Main-experience-conatiner
    .conatiner-feature-wrapper-two
    .feature-wrapper-inner-two {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
  }
  .feature-description-wrapper-two {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 1rem !important;
    padding-right: 2rem !important;
    width: 100% !important;
  }
  .feature-heading-two, .title-feature {
    font-size: 30px !important;
    color: #ffffff;
    text-align: left;
    line-height: 1.1 !important;
    font-family: "ProximaNova-SemiBold";
  }
  .feature-details-two {
    width: 100% !important;
    font-size: 20px;
    font-family: "ProximaNova-Regular";
    color: #ffffff;
    padding: 20px 0px;
    text-align: left;
  }
  .feature-details-box-two {
    width: 100% !important;
    height: 14rem !important;
    background-color: #3e3e3e;
  }
  .upcoming-heading {
    font-size: 29px !important;
    color: #d0d0d0;
    text-align: left !important;
    float: left !important;
  }
  .features-wrapper {
    font-size: 14px !important;
    line-height: 50px !important;
    letter-spacing: 2.8px !important;
    margin-top: 290px !important;
  }
  .Main-experience-conatiner .solo-view-details .player-info-wrapper {
    width: 90%;
    height: unset;
    position: absolute;
    top: 10px;
    left: 0%;
    right: 0;
    margin: 0 auto;
    z-index: 1;
  }
  .Main-experience-conatiner .solo-view-details .solo-view-bg img {
    width: 100%;
    height: 100%;
    object-fit: none;
    position: relative;
  }

  .Main-experience-conatiner .upcoming-wrapper .shows-btn {
    width: 200px;
    height: 50px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .Main-experience-conatiner .solo-view-details .features-wrapper {
    color: #ffffff;
    font-size: 35px !important;
    line-height: 50px !important;
    letter-spacing: 2.8px !important;
    margin-top: 2.5rem !important;
  }
  .Main-experience-conatiner .solo-view-details .player-info-wrapper {
    width: 90%;
    height: 460px;
  }
  .Main-experience-conatiner
    .conatiner-feature-wrapper
    .feature-wrapper-inner
    .feature-description-wrapper
    .feature-details {
    width: 300px;
    line-height: 25px;
  }
  .Main-experience-conatiner
    .conatiner-feature-wrapper
    .feature-wrapper-inner
    .feature-details-box {
    width: 48rem !important;
    height: 20rem !important;
  }
  .Main-experience-conatiner
    .conatiner-feature-wrapper
    .feature-wrapper-inner
    .feature-description-wrapper
    .feature-heading {
    font-size: 25px;
    line-height: 25px;
  }
  .Main-experience-conatiner
    .conatiner-feature-wrapper-two
    .feature-wrapper-inner-two
    .feature-description-wrapper-two
    .feature-details-two {
    width: 300px;
    font-size: 20px;
    line-height: 25px;
  }
  .Main-experience-conatiner
    .conatiner-feature-wrapper-two
    .feature-wrapper-inner-two
    .feature-description-wrapper-two
    .feature-heading-two {
    font-size: 25px;
    line-height: 25px;
  }
  .Main-experience-conatiner
    .conatiner-feature-wrapper-two
    .feature-wrapper-inner-two
    .feature-details-box-two {
    width: 48rem !important;
    height: 20rem !important;
  }
  .Main-experience-conatiner .upcoming-wrapper .shows-btn {
    width: 220px;
    height: 50px;
    font-size: 14px;
  }
  .Main-experience-conatiner
    .conatiner-feature-wrapper-two
    .feature-wrapper-inner-two {
    gap: 2rem;
  }
  .Main-experience-conatiner
    .conatiner-feature-wrapper
    .feature-wrapper-inner
    .feature-description-wrapper
    .feature-heading {
    display: flex;
    align-items: center;
    gap: 3rem;
  }
  .Main-experience-conatiner
    .conatiner-feature-wrapper-two
    .feature-wrapper-inner-two
    .feature-description-wrapper-two
    .feature-heading-two {
    display: flex;
    align-items: center;
    gap: 3rem;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1900px) {
  .Main-experience-conatiner .conatiner-feature-wrapper {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .Main-experience-conatiner .solo-view-details .features-wrapper {
    font-size: 20px;
    line-height: 35px;
    letter-spacing: 1.6;
  }
  .Main-experience-conatiner
    .conatiner-feature-wrapper
    .feature-wrapper-inner
    .feature-description-wrapper
    .feature-heading {
    font-size: 35px;
    line-height: 35px;
  }
  .Main-experience-conatiner
    .conatiner-feature-wrapper
    .feature-wrapper-inner
    .feature-description-wrapper
    .feature-details {
    width: 550px;
    font-size: 16px;
    line-height: 25px;
  }
  .Main-experience-conatiner
    .conatiner-feature-wrapper
    .feature-wrapper-inner
    .feature-details-box {
    width: 35rem;
    height: 17rem;
    background-color: #3e3e3e;
  }
  .Main-experience-conatiner
    .conatiner-feature-wrapper-two
    .feature-wrapper-inner-two
    .feature-details-box-two {
    width: 35rem;
    height: 17rem;
    background-color: #3e3e3e;
  }
  .Main-experience-conatiner .conatiner-feature-wrapper-two {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .Main-experience-conatiner
    .conatiner-feature-wrapper-two
    .feature-wrapper-inner-two
    .feature-description-wrapper-two
    .feature-heading-two {
    font-size: 35px;
    line-height: 35px;
  }
  .Main-experience-conatiner
    .conatiner-feature-wrapper-two
    .feature-wrapper-inner-two
    .feature-description-wrapper-two
    .feature-details-two {
    width: 550px;
    font-family: "Proxima-Nova-Medium";
    font-size: 16px;
    line-height: 25px;
  }
  .Main-experience-conatiner .upcoming-wrapper .upcoming-heading {
    font-size: 35px;
    line-height: 35px;
    margin-top: 1rem;
  }
  .Main-experience-conatiner .upcoming-wrapper .shows-btn {
    width: 215px;
    height: 55px;
    font-size: 14px;
  }
}
