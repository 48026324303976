// create account
.main-register {
  width: 702px;
  height: 558px;
  margin: 0 auto;
  background-color: black;
  opacity: 75%;
  display: flex;
  flex-direction: column;
  // justify-content: start;
  align-items: center;
  // gap: 1rem;
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;

  // create-account-panel
  .create-account-panel {
    display: flex;
    flex-direction: column;
    align-items: center;

    .create-account-panel-heading {
      font-size: 40px;
      letter-spacing: -0.4px;
      line-height: 45px;
      font-family: "ProximaNova-SemiBold";
      text-align: center;
      color: #ffffff;
      margin: 35px 35px 0px 35px;
    }

    .create-account-panel-description {
      margin: 12px 113px 0px 114px;
      line-height: 28px;
      font-size: 20px;
      color: #99999d;
      text-align: center;
      font-family: "ProximaNova-Regular";
      // padding: 0px 9rem;
    }

    .create-account-btn-first {
      width: 270px;
      height: 47px;
      margin-top: 26px;

      img {
        width: 100%;
      }
    }

    .create-account-btn-second {
      width: 270px;
      height: 47px;
      margin-top: 33px;

      img {
        width: 100%;
      }
    }

    .devide-lines {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      width: 255px;
      height: 24px;
      font-family: "ProximaNova-Regular";
      font-size: 16px;
      margin-top: 100px;
      margin-bottom: 28px;

      hr {
        margin: 0;
        padding: 0;
        width: 5rem;
        height: 0;
        color: gray;
        border-bottom: 1px solid #999999;
      }

      span {
        margin: 0;
        padding: 0;
        text-decoration: none;
        font-family: "ProximaNova-Bold";
        color: gray;
        font-size: 15px;
        padding-bottom: 5px;
      }
    }
  }

  // verify-email-panel
  .verify-email-panel {
    .verify-email-panel-heading {
      font-size: 40px;
      letter-spacing: -0.4px;
      text-align: center;
      color: #ffffff;
      font-family: "ProximaNova-SemiBold";
      margin: 35px 33px 0px 34px;
      line-height: 45px;
    }

    .verify-email-panel-description {
      font-size: 20px;
      color: #999999;
      text-align: center;
      margin-top: 12px;
      font-family: "ProximaNova-Regular";
      padding: 0 9rem;
      line-height: 28px;
    }
  }

  // create your profile
  .create-email-panel {
    .create-email-panel-heading {
      font-size: 40px;
      letter-spacing: -0.4px;
      text-align: center;
      color: #ffffff;
      font-family: "ProximaNova-SemiBold";
      margin: 35px 33px 0px 34px;
      line-height: 45px;
    }

    .cretate-email-panel-description {
      font-size: 20px;
      color: #999999;
      text-align: center;
      margin-top: 12px;
      font-family: "ProximaNova-Regular";
      padding: 0 9rem;
      line-height: 28px;
      margin-bottom: 15px;
    }
  }

  // create-password-panel
  .create-password-panel {
    .create-password-panel-heading {
      font-size: 30px;
      letter-spacing: -0.3px;
      text-align: center;
      color: #ffffff;
      font-family: "ProximaNova-SemiBold";
      margin-top: 35px;
    }

    .create-password-panel-description {
      font-size: 16px;
      color: #999999;
      text-align: center;
      margin-top: 24px;
      font-family: "ProximaNova-Regular";
      padding: 0 2rem;
    }
  }

  // Middel-box
  .Middel-box {
    text-align: center;

    .fill-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .fill-box-heading {
        color: #ffffff;
        font-size: 20px;
        font-family: "ProximaNova-Regular";
        text-align: left;
        width: 260px;
        width: 260px;
        height: 34px;
        // margin-top: 38px;
        // margin-top: 38px;
        // margin: 0 0 1rem 2rem;
      }

      input {
        cursor: pointer;
        background-color: #292929;
        padding: 10px;
        font-size: 16px;
        line-height: 45px;
        font-family: "ProximaNova-Regular";
        color: #ffffff;
        // margin-top: 10px;
        // margin-top: 10px;
        box-sizing: border-box;
        border: 1px solid #707070;
        width: 260px;
        width: 260px;
        height: 47px;
        outline: none;
      }
    }

    .sub-description {
      font-size: 16px;
      text-align: center;
      color: #999999;
      // font-family: "ProximaNova-Regular";
      font-family: "ProximaNova-Bold";
      margin-top: 45px;
      margin-bottom: 0px;
      line-height: 22px;

      .sub-des-dark {
        font-size: 16px;
        text-align: center;
        color: #ffffff;
        font-family: "ProximaNova-Bold";
        line-height: 22px;
      }
    }

    .resend-code {
      font-size: 16px;
      text-align: center;
      color: #d0d0d0;
      font-family: "ProximaNova-Bold";
      margin-top: 15px;
      margin-bottom: 65px;
      line-height: 22px;
      background-color: transparent;
      border: none;
      display: block;
      margin: auto;
      cursor: pointer;
    }

    .option-btn-email {
      width: 256px;
      height: 60px;
      margin: 0 auto;
      opacity: 50%;
      background: linear-gradient(to bottom,
          rgba(102, 14, 75, 1),
          rgba(69, 16, 54, 0.78));
      opacity: 100%;
      border: 1px solid #707070;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff;
      font-family: "Proxima-Nova-Medium";
      font-size: 18px;
      margin-top: 65px;
      margin-bottom: 117px;
      cursor: pointer;

      &:hover {
        border: 1px solid #707070;
        background: linear-gradient(to bottom,
            rgba(255, 108, 226, 1),
            rgba(194, 66, 168, 0.78));
      }
    }

    .sub-description-pass {
      font-size: 16px;
      color: gray;
      text-align: center;
      width: 236px;
      height: 139px;
      cursor: pointer;

      span {
        text-decoration: none;
        font-family: "ProximaNova-Bold";
        font-size: 16px;
        // font-weight: bold;
        color: #ffffff;

        a {
          color: white;
          text-decoration: none;
        }
      }

      ul {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: left;
      }

      li {
        color: #999999 !important;
      }

      li::marker {
        font-size: 1.5rem;
        color: #3e3e3e;
      }
    }

    .option-wrapper-btn-pass-cre {
      .btn-with {
        width: 256px;
        height: 47px;
        margin-bottom: 152px;
        margin-top: 14px;

        img {
          width: 100%;
        }
      }
    }

    .wrapper-password {
      .fill-pass-heading {
        margin-top: 24px;
        font-size: 18px;
        text-align: left;
        color: #ffffff;
        font-family: "ProximaNova-Regular";
      }

      input {
        cursor: pointer;
        width: 100%;
        background-color: #262626;
        padding: 10px;
        font-size: 16px;
        line-height: 45px;
        font-family: "ProximaNova-Regular";
        color: #ffffff;
        margin-top: 10px;
        box-sizing: border-box;
        border: 1px solid #d0d0d0;
        width: 270px;
        height: 47px;
        outline: none;
        margin-bottom: 27px;
      }
    }
  }

  // bottom-section
  .bottom-section {
    .bottom-section-info {
      text-align: center;
      font-size: 16px;
      color: #999999;
      font-family: "ProximaNova-Regular";
      line-height: 22px;
      margin: 34px 179px 28px 180px;

      .bottom-section-subinfo {
        text-align: center;
        font-size: 16px;
        color: #d0d0d0;
        // font-family: "ProximaNova-Regular";
        font-family: "ProximaNova-SemiBold";
        line-height: 22px;
        cursor: pointer;

        a {
          text-align: center;
          font-size: 14px;
          color: #d0d0d0;
          // font-family: "ProximaNova-Regular";
          font-family: "ProximaNova-SemiBold";
          line-height: 22px;
          cursor: pointer;
          text-decoration: none;
        }
      }
    }

    .bottom-section-heading-wrapper {
      font-size: 18px;
      color: #d0d0d0;
      text-align: center;
      font-family: "ProximaNova-Regular";
      margin-bottom: 30px;
      margin-top: 20px;

      .bottom-section-subheading-wrapper {
        a {
          font-size: 18px;
          color: #ffffff;
          text-align: center;
          font-family: "ProximaNova-SemiBold";
          text-decoration: none;
        }
      }
    }
  }
} 
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .main-register {
    width: 16rem;
    top: 15% !important;
    top: 10% !important;
    height: auto;

    .create-account-panel .create-account-panel-description {
      margin-top: 15px !important;
      line-height: 20px;
      font-size: 13px;
      color: #99999d;
      text-align: center;
      font-family: "ProximaNova-Regular";
      margin: 0;
    }
  }

  .main-register .bottom-section .bottom-section-info {
    margin-top: 34px;
    padding: 0 0rem;
    font-size: 12px;
    margin: 34px 0px 0px 0px;
  }

  .main-register .verify-email-panel .verify-email-panel-heading {
    font-size: 35px;
    letter-spacing: -0.35px;
    text-align: center;
    color: #ffffff;
    font-family: "ProximaNova-SemiBold";
    margin: 35px 0px 0px 0px;
    line-height: 40px;
  }

  .main-register .verify-email-panel .verify-email-panel-description {
    font-size: 16px;
    color: #999999;
    text-align: center;
    margin-top: 12px;
    font-family: "ProximaNova-Regular";
    line-height: 24px;
    padding: 0;
  }

  .main-register .create-email-panel .create-email-panel-heading {
    font-size: 30px;
    letter-spacing: -0.35px;
    text-align: center;
    color: #ffffff;
    font-family: "ProximaNova-SemiBold";
    margin: 35px 0px 0px 0px;
    line-height: 35px;
  }

  .main-register .create-email-panel .cretate-email-panel-description {
    font-size: 20px;
    color: #999999;
    text-align: center;
    margin-top: 12px;
    font-family: "ProximaNova-Regular";
    padding: 0 0rem;
    line-height: 28px;
    margin-bottom: 15px;
  }

  .main-register .create-account-panel .create-account-panel-heading {
    margin-top: 10px;
    font-size: 29px;
    letter-spacing: -0.35px;
    line-height: 40px;
    font-family: "ProximaNova-SemiBold";
    text-align: center;
    color: #ffffff;
    margin: 35px 0px 0px 0px !important;
  }

  .main-register .create-account-panel .devide-lines {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 255px;
    height: 24px;
    font-family: "ProximaNova-Regular";
    font-size: 16px;
    margin-top: 50px !important;
  }
  .main-register .Middel-box .sub-description {
    font-size: 15px;
    line-height: 20px;
}
.main-register .Middel-box .sub-description .sub-des-dark {
  font-size: 14px;
  line-height: 20px;
}
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {}

@media only screen and (min-width: 1024px) and (max-width: 1900px) {
  .main-register {
    width: 559px;
    height: 565px;
  }

  .main-register .create-account-panel .create-account-panel-heading {
    font-size: 30px;
    letter-spacing: -0.4px;
    line-height: 30px;
  }

  .main-register .create-account-panel .create-account-panel-description {
    line-height: 20px;
    font-size: 16px;
    margin: 0rem 7rem;
    margin-top: 1rem;
  }

  .main-register .create-account-panel .devide-lines {
    width: 255px;
    height: 24px;
    margin-top: 50px;
    margin-top: 50px;
    margin-bottom: 0px;
  }

  .main-register .create-account-panel .devide-lines {
    width: 200px;
  }

  .main-register .Middel-box .fill-box .fill-box-heading {
    color: #ffffff;
    font-size: 18px;
    font-family: "ProximaNova-Regular";
    text-align: left;
    width: 270px;
    height: 34px;
    margin-top: 31px;
    margin-top: 31px;
    padding-left: 30px;
  }

  .main-register .Middel-box .fill-box input {
    margin-top: 0px;
    width: 242px;
    height: 47px;
  }

  .main-register .bottom-section .bottom-section-info {
    text-align: center;
    font-size: 16px;
    line-height: 22px;
    margin: 34px 7.5rem;
  }

  .main-register .verify-email-panel .verify-email-panel-heading {
    font-size: 30px;
    line-height: 30px;
  }

  .main-register .verify-email-panel .verify-email-panel-description {
    font-size: 17px;
    padding: 0 7rem;
    line-height: 25px;
  }

  .main-register .Middel-box .option-btn-email {
    width: 207px;
    height: 50px;
    font-size: 14px;
    margin-bottom: 0px;
  }
}

.mb-15 {
  margin-bottom: 20px;
}

.timer-wrap {
  display: flex;
  span {
    display: flex;
  }
  .w-20 {
    width: 25px;
    display: block !important;
  }
}
